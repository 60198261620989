import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OrderService} from '../../../services/order.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import * as moment from 'moment';
import {Order, Slot, UserSpecificConfig} from '../../../models/Order';
import {AuthorizedApiService} from '../../../services/authorized-api.service';
import {AppStateService} from '../../../services/app-state.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-select-date-time-dialog',
  templateUrl: './select-date-time-dialog.component.html',
  styleUrls: ['./select-date-time-dialog.component.css']
})
export class SelectDateTimeDialogComponent implements OnInit, OnDestroy {

  selectedDateKey: string;
  title: string;
  config: UserSpecificConfig;
  errorMessage: string;
  isWorking = false;
  range: string;
  time: any;
  times = ["1", "2", "3"];
  dates: any;
  slots: Slot[];
  noDates = false;
  namespace: string;
  namespaces: any[];
  private sub: Subscription;
  private order: Order;

  constructor(@Inject(MAT_DIALOG_DATA) data, private dialogRef: MatDialogRef<SelectDateTimeDialogComponent>, private orderService: OrderService,
              private appState: AppStateService, private snackbar: MatSnackBar, private api: AuthorizedApiService) {
  }

  ngOnInit(): void {
    this.sub = this.appState.observeAppState().subscribe(appState => {
      this.order = appState.order;
      this.config = appState.order.user_cfg;
      this.title = `Välj tid och datum`;
      this.namespaces = appState.cfg?.dt?.takeaway?.slotting?.namespaces;
      if (this.namespaces != null) {
        console.log(this.namespaces);
      } else {
        this.fetchSlotDates();
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }

  selectedTime() {
    console.log(this.time);
    if (this.time == null) { return; }
    this.isWorking = true;
    this.sub?.unsubscribe();
    this.orderService.updateCollected(undefined, this.time, undefined, undefined, this.selectedDateKey, this.namespace).subscribe(res => {
      this.isWorking = false;
      this.dialogRef.close();
    });
  }

  selectedDate(dateKey) {
    console.log(dateKey);
    this.selectedDateKey = dateKey;
    this.isWorking = true;
    this.api.fetchFreeSlots(dateKey, this.namespace).subscribe(slots => {
      this.isWorking = false;
      if (slots.length === 0) {
        this.noDates = true;
        this.slots = null;
        return;
      }
      this.noDates = false;
      this.slots = slots;
      if (slots.length > 0) {
        const sameTimeSlot = slots.find(s => s.time === this.time);
        console.log(sameTimeSlot);
        if (sameTimeSlot) {
          this.time = sameTimeSlot.time;
        } else {
          this.time = slots[0].time;
        }
      } else {
        this.time = undefined;
      }
      console.log("slots: ", slots);
    });
  }

  private fetchSlotDates() {
    this.isWorking = true;
    this.dates = undefined;
    this.slots = undefined;
    this.time = undefined;
    this.api.fetchSlotDates(this.namespace).subscribe(dates => {
      this.isWorking = false;
      console.log("dates: ", dates);
      this.noDates = dates.days.length === 0;
      if (this.noDates) {
        return;
      }
      for (const d of dates.days) {
        d.date = d.date_key.substring(8);
      }
      this.dates = dates.days;
      this.selectedDate(dates.days[0].date_key);
    });
  }

  nsChanged() {
    console.log(this.namespace);
    this.fetchSlotDates();
  }
}

