import * as firebase from 'firebase';
import Timestamp = firebase.firestore.Timestamp;
import * as moment from 'moment';
import {TimeUtils} from '../utils/Utils';
import {DeliveryType, Identity} from '../objects/Identity';

export interface FSConfig {
  data: string;
  venue_id: number;
}

export interface FeaturesConfig {
  use_tele_login?: boolean;
}

export interface VenueConfig {
  dt?: ConfigDeliverTypes;
  name: string;
  allow_reorder: boolean;
  start_tab?: string;
  deliver_type: string;
  expected_delivery_time: number;
  payment?: ConfigPayment;
  show_discount: boolean;
  show_overview: boolean;
  bus_name?: string;
  org_number?: string;
  swish_info?: string;
  text_db?: {};
  use_translations: boolean;
  kiosk_state?: string;
  features?: FeaturesConfig;
}

export interface ConfigDeliverTypes {
  inhouse?: ConfigDeliverType;
  takeaway?: ConfigDeliverType;
  roomservice?: ConfigDeliverType;
  pickup?: ConfigDeliverType;
  foodcourt?: ConfigDeliverType;
}

export interface ConfigDeliverType {
  open: boolean;
  show_time: boolean;
  prep_time?: number;
  title: string;
  desc: string;
  menu_filter_tag?: string;
  table_regex?: string;
  kitchen_time?: ConfigTime[];
  call_waiter?: boolean;
  selectable?: boolean;
  slotting?: {namespaces: {id: string, name: string}[]};
}

export interface ConfigTime {
  start: number;
  stop: number;
  day: number[];
}

export interface ConfigPayment {
  prepaid_id: string;
  prepaid_name: string;
  swish: boolean;
  card: boolean;
  viva: ConfigViva;
  google_pay_mode: string;
}

export interface ConfigViva {
  active: boolean;
  merchant_id: string;
}

export interface FSConnectAccount {
  state: string;
  date?: Timestamp;
}

export function findPlausibleNonLoggedInDeliverType(cfg: VenueConfig, identity: Identity) {
  if (hasMoreThanOneDeliverType(cfg)) {
    if (identity?.table) {
      if (identity.table.startsWith("Takeaway") && cfg.dt?.takeaway) {
        return DeliveryType.Takeaway;
      }
      if (identity.table.startsWith("Pickup") && cfg.dt?.pickup) {
        return DeliveryType.Pickup;
      }
      if (identity.table.startsWith("Room") && cfg.dt?.roomservice) {
        return DeliveryType.Roomservice;
      }
      if (identity.table.startsWith("Food") && cfg.dt?.foodcourt) {
        return DeliveryType.Foodcourt;
      }
    } else {
      return identity?.deliveryType;
    }
  } else if (cfg.dt) {
    if (cfg.dt.takeaway) {
      return DeliveryType.Takeaway;
    }
    if (cfg.dt.pickup) {
      return DeliveryType.Pickup;
    }
    if (cfg.dt.roomservice) {
      return DeliveryType.Roomservice;
    }
    if (cfg.dt.foodcourt) {
      return DeliveryType.Foodcourt;
    }
  }
  return "inhouse";
}

export function hasMoreThanOneDeliverType(cfg: VenueConfig): boolean {
  const pickup = cfg.dt?.pickup?.selectable ?? (cfg.dt?.pickup != null);
  const t = (cfg.dt?.inhouse ? 1 : 0) + (cfg.dt?.takeaway ? 1 : 0) + (pickup ? 1 : 0) + (cfg.dt?.foodcourt ? 1 : 0);
  return t > 1;
}

export function isKitchenOpen(cfg: VenueConfig): boolean {
  const kitchen_time = cfg?.dt?.inhouse?.kitchen_time;
  if (kitchen_time) {
    const day = moment().isoWeekday() - 1;
    for (const kt of kitchen_time) {
      if (kt.day?.includes(day)) {
        const hourDec = TimeUtils.getCurrentH30();
        return hourDec >= kt.start && hourDec < kt.stop;
      }
    }
  }
  return true;
}
