<div class="wrapper" style="display: flex;justify-content: center;">
  <div style="width: 240px;">
    <div class="formGroup">
      <div *ngIf="!authService.signInProgress">

        <div *ngIf="!loginPhone && useTeleLogin">
          <!-- Form to enter phone number -->
          <div class="btn phone_btn" (click)="selectPhoneLogin()" mat-ripple matRippleColor="#ffffff80">
            <div style="margin-left: 16px;">{{getString("Verifiera med telefon")}}</div>
          </div>
        </div>

        <div *ngIf="loginPhone && !sentCode">
          <!-- Form to enter phone number -->
          <input type="tel" [(ngModel)]="phoneNumber" autocomplete="tel" placeholder="Ange mobilnummer" class="enter-mobile">
          <div *ngIf="!waiting" class="btn phone_btn" (click)="sendCode()" mat-ripple matRippleColor="#ffffff80">
            <div style="margin-left: 16px;">{{getString("Skicka kod")}}</div>
          </div>
          <div *ngIf="waiting" style="display: flex; flex-direction: row; align-items: center; justify-content: center;height: 92px;">
            <span><mat-spinner class="send-code-spinner" diameter="20"></mat-spinner></span>
            <span style="margin-left: 16px;color:grey;">{{getString("Skickar kod...")}}</span>
          </div>
        </div>

        <div *ngIf="loginPhone && sentCode">
          <!-- Form to enter verification code -->
          <input type="tel" [(ngModel)]="verificationCode" (ngModelChange)="onCodeChange()" placeholder="Ange SMS-koden" class="enter-mobile">
          <div *ngIf="!waiting" class="btn phone_btn" (click)="verifyCode()" mat-ripple matRippleColor="#ffffff80">
            <div style="margin-left: 16px;">{{getString("Verifiera koden")}}</div>
          </div>
          <div *ngIf="waiting" style="display: flex; flex-direction: row; align-items: center; justify-content: center;height: 92px;">
            <span><mat-spinner class="send-code-spinner" diameter="20"></mat-spinner></span>
            <span style="margin-left: 16px;color:grey;">{{getString("Verifierar kod...")}}</span>
          </div>
        </div>

        <div *ngIf="!loginPhone && !isAndroid" class="btn apple_btn" (click)="authService.appleAuth()" mat-ripple matRippleColor="#ffffff80">
          <div style="width:25px;margin-left: -16px;margin-top: 5px;">
            <svg width="31px" height="44px" viewBox="0 0 31 44" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <g id="Left-White-Logo-Medium" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <rect id="Rectangle" fill="#000000" x="0" y="0" width="31" height="44"></rect>
                <path d="M15.7099491,14.8846154 C16.5675461,14.8846154 17.642562,14.3048315 18.28274,13.5317864 C18.8625238,12.8312142 19.2852829,11.852829 19.2852829,10.8744437 C19.2852829,10.7415766 19.2732041,10.6087095 19.2490464,10.5 C18.2948188,10.5362365 17.1473299,11.140178 16.4588366,11.9494596 C15.9152893,12.56548 15.4200572,13.5317864 15.4200572,14.5222505 C15.4200572,14.6671964 15.4442149,14.8121424 15.4562937,14.8604577 C15.5166879,14.8725366 15.6133185,14.8846154 15.7099491,14.8846154 Z M12.6902416,29.5 C13.8618881,29.5 14.3812778,28.714876 15.8428163,28.714876 C17.3285124,28.714876 17.6546408,29.4758423 18.9591545,29.4758423 C20.2395105,29.4758423 21.0971074,28.292117 21.9063891,27.1325493 C22.8123013,25.8038779 23.1867451,24.4993643 23.2109027,24.4389701 C23.1263509,24.4148125 20.6743484,23.4122695 20.6743484,20.5979021 C20.6743484,18.1579784 22.6069612,17.0588048 22.7156707,16.974253 C21.4353147,15.1382708 19.490623,15.0899555 18.9591545,15.0899555 C17.5217737,15.0899555 16.3501271,15.9596313 15.6133185,15.9596313 C14.8161157,15.9596313 13.7652575,15.1382708 12.521138,15.1382708 C10.1536872,15.1382708 7.75,17.0950413 7.75,20.7911634 C7.75,23.0861411 8.64383344,25.513986 9.74300699,27.0842339 C10.6851558,28.4129053 11.5065162,29.5 12.6902416,29.5 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
              </g>
            </svg>
          </div>
          <div style="margin-left: 16px;">{{getString("Fortsätt med")}} Apple</div>
        </div>

        <div *ngIf="!loginPhone" class="btn google-button2" mat-ripple (click)="authService.googleAuth()">
          <div style="width:25px;margin-left: -16px;margin-top: 5px;">
            <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
              <path d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z" id="Shape" fill="#EA4335" />
              <path d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z" id="Shape" fill="#FBBC05" />
              <path d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z" id="Shape" fill="#4285F4" />
              <path d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z" fill="#34A853" />
            </svg>
          </div>
          <div style="margin-left: 16px;">{{getString("Fortsätt med")}}  Google</div>
        </div>

        <div *ngIf="!loginPhone" class="notice">Genom att gå vidare samtycker du till skapandet av en profil där dina köp registreras. Du kan läsa om hur data lagras under "Mitt konto" och även ta bort din profil.</div>
        <div *ngIf="!phoneLoginError && loginPhone && !sentCode" class="notice2">Ange ditt mobilnummer, så skickar vi en verifieringskod till dig via SMS.</div>
        <div *ngIf="!phoneLoginError && loginPhone && sentCode" class="notice2">Kolla i din telefon efter SMS-koden och ange den här ovan.</div>
        <div *ngIf="phoneLoginError" class="noticeErr">{{phoneLoginError}}</div>
      </div>
      <mat-spinner class="sign-in-spinner" *ngIf="authService.signInProgress"></mat-spinner>
      <span *ngIf="authService.signInProgress" class="spinner-border spinner"></span>
    </div>
  </div>
</div>

<div style="display: flex; align-items: center; justify-content: center;margin-bottom: 8px;">
  <!-- recaptcha-container will hold the reCAPTCHA widget -->
  <div id="recaptcha-container"></div>
</div>
