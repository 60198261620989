import {Injectable, Injector} from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Order, OrderItem } from '../models/Order';
import { AuthorizedApiService } from './authorized-api.service';
import { filter, first, tap } from 'rxjs/operators';
import {TranslateService} from "./translate.service";
import {MenuItem} from "../models/FSMenu";
import {HackUtils} from "../utils/Utils";

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private EmptyOrder: Order = { amount: 0, current: false, discount: 0, discount_formatted: '', discount_total: 0, items: [], status: '', title: '', total: '', total_deliver: 0, total_pre: 0, vat: '', user_cfg: {}, venue_id: 0 };
  private currentOrderSubject = new BehaviorSubject<Order | null>(null);
  private translate: TranslateService;

  constructor(private apiService: AuthorizedApiService, private injector: Injector) {
    //this.initOrderState();
    console.log("order service ctor");
  }

  currentOrder(): Observable<Order> {
    return this.currentOrderSubject.pipe(filter<Order>(o => o != null));
  }

  orderSnapshot(): Observable<Order> {
    return this.currentOrder().pipe(first());
  }

  // private initOrderState(): void {
  //   //this.authService.observeNewlyLoggedInUserOrder$().subscribe( order => { this.setState(order); });
  //   this.authService.isLoggedIn().subscribe( loggedIn => {
  //     if (loggedIn === false) {
  //       this.setState(this.EmptyOrder);
  //     }
  //   });
  // }

  public setState(order: Order) {
    console.log("Order state:");
    console.log(order);
    if (this.translate == null) {
      this.translate = this.injector.get(TranslateService);
    }
    this.translate.order(order);
    this.currentOrderSubject.next(order);
  }

  public fetchCurrentOrder(tableCode?: string): Observable<Order> {
    return this.apiService.getOrder(tableCode).pipe(tap<Order>(ord => this.setState(ord)));
  }

  // resolveChat(user: firebase.User, table: string, venueId: string, deliveryType?: string): Observable<Order> {
  //   return this.nonAuthApiService.resolveChat(user, table, venueId, deliveryType).pipe(tap<Order>(ord => this.setState(ord)));
  // }

  addItemToOrder(itemId: string, comment: string, uid: string, postActionData?: any, sameAsRid?: string): Observable<Order> {
    return this.apiService.addItemToOrder(itemId, comment, uid, postActionData, sameAsRid).pipe(tap<Order>(ord => this.setState(ord)));
  }

  addItemsToOrder(itemIds: string[], parentRID: string, itemIncluded: boolean): Observable<Order> {
    return this.apiService.addItemsToOrder(itemIds, parentRID, itemIncluded).pipe(tap<Order>(ord => this.setState(ord)));
  }

  removeItemFromOrder(itemId: string, isPackage:boolean): Observable<Order> {
    return this.apiService.removeItemFromOrder(itemId, isPackage).pipe(tap<Order>(ord => this.setState(ord)));
  }

  changeDeliverType(key: string): Observable<Order> {
    return this.apiService.changeDT(key).pipe(tap<Order>(ord => this.setState(ord)));
  }

  leaveSmartTable(): Observable<Order> {
    return this.apiService.leaveSmartTable().pipe(tap<Order>(ord => this.setState(ord)));
  }

  changeTable(code: string, kiosk?: string): Observable<Order> {
    return this.apiService.changeTable(code, kiosk).pipe(tap<Order>(ord => this.setState(ord)));
  }

  confirmTable(): Observable<Order> {
    return this.apiService.confirmTable().pipe(tap<Order>(ord => {
      this.setState(ord);
    }));
  }

  addDiscountCode(code: string): Observable<Order> {
    return this.apiService.addDiscountCode(code).pipe(tap<Order>(ord => this.setState(ord)));
  }

  placeOrder(): Observable<Order> {
    // TODO this should read order from response when server logic is changed to return
    return this.apiService.placeOrder().pipe(tap<Order>(ord => this.clearCurrentOrder(ord)));
  }

  updateCollected(phoneNumber?: string, time?: any, pickupLocation?: any, roomnumber?: any, dateKey?: string, namespace?: string, anonEmail?, genericData?: any, customId?: string, customValue?: any): Observable<Order> {
    return this.apiService.updateCollected(phoneNumber, time, pickupLocation, roomnumber, dateKey, namespace, anonEmail, genericData, customId, customValue).pipe(tap<Order>(ord => this.setState(ord)));
  }

  payWithPrepaid(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string): Observable<Order> {
    return this.apiService.payWithPrepaid(tip, receiptKey, itemRefs, plKey, payableRefs).pipe(tap<Order>(ord => this.setState(ord)));
  }

  payWithAccount(tip: any, receiptKey?: string, itemRefs?: any[], plKey?: string, payableRefs?: string): Observable<Order> {
    return this.apiService.payWithAccount(tip, receiptKey, itemRefs, plKey, payableRefs).pipe(tap<Order>(ord => this.setState(ord)));
  }

  payWithFree(tip: any, receiptKey?: string, itemRefs?: any[]): Observable<Order> {
    return this.apiService.payWithFree(tip, receiptKey, itemRefs).pipe(tap<Order>(ord => this.setState(ord)));
  }

  // TODO this is not a good solution, its only working as long as there are no errors server side, fetch from server!!!
  public clearCurrentOrder(newOrder?: Order): void {
    const order = this.EmptyOrder;
    const current = newOrder ?? this.currentOrderSubject.getValue();
    if (current !== null) {
      order.user_cfg = current.user_cfg;
    }
    this.currentOrderSubject.next(order);
  }

  // Get item count in the current order for given itemId
  // If unique is:
  //  - true it will count items with the unique order id
  //  - false it will count items with the menu item id
  getCount(itemId: string, unique = false, orderItemIsPackage: boolean = false): number {
    const order = this.currentOrderSubject.getValue();
    if (!order) {
      return 0;
    }
    const itemsFilter = (itm) => {
      // Check for item identity based on the 'unique' flag
      const itemMatch = unique ? itm.id === itemId : itm.item === itemId;
      // If a packageId is provided, ensure it matches, otherwise check for non-package items
      const packageMatch = orderItemIsPackage === (itm.package != null);

      return itemMatch && packageMatch;
    };

    const mis = order.items.filter(itemsFilter);

    // Sum the counts of the filtered items
    return mis.reduce((acc, itm) => acc + itm.count, 0);
  }

  getItems(itemId: string): OrderItem[] {
    const order = this.currentOrderSubject.getValue();
    if (!order) {
      return null;
    }
    const items = order.items.filter(itm => itm.item === itemId);
    return items;
  }

  getOrderOrNull(): Order {
    return this.currentOrderSubject.getValue();
  }

  getPriceForItem(item: MenuItem) {
    const pl = this.getOrderOrNull()?.user_cfg.smart_table?.price_list;
    let price = item.formatted;
    if (pl != null) {
      const plPrice = item?.prices?.find(p => p.name === pl)?.price;
      if (plPrice!=null) {
        price = plPrice + " kr";
      }
    }
    if(item.package != null) {
      const totalSum = item.package.included.reduce((acc, curr) => acc + curr.price, 0);
      price = totalSum + " kr";
    }
    return price;
  }

  getMissingCollectInfo(customCollects: any[]) {
    if (customCollects == null || customCollects.length === 0) {
      return undefined;
    }

    const missing = [];
    for (const cc of customCollects) {
      if (cc.required && (cc.value == null || HackUtils.isWhitespace(cc.value))) {
        missing.push(cc);
      }
    }
    if (missing.length > 0) {
      const str = missing.map(m => m.title).join(", ");
      return "Var god och ange: " + str;
    }
    return undefined;
  }
}
