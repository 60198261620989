import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {MenuCategory, MenuItem, MenuSection} from '../../models/FSMenu';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {combineLatest, Subscription} from 'rxjs';
import {AppStateService} from '../../services/app-state.service';
import {AppState} from '../../models/AppState';
import {SelectDeliverTypeComponent} from '../sidebar/select-deliver-type/select-deliver-type.component';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import MoneyUtils from '../../utils/Utils';
import {AuthorizedApiService} from '../../services/authorized-api.service';
import {UserSpecificCustomerNotice} from '../../models/Order';
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-menu-page',
  templateUrl: './menu-page.component.html',
  styleUrls: ['./menu-page.component.scss']
})
export class MenuPageComponent implements OnInit, OnDestroy {
  sections: MenuSection[];
  root?: MenuSection;
  isLoading = true;
  pageId: string;
  appState: AppState;
  private venueId: string;
  private sectionId: string;
  selectedSection: MenuSection;
  private sub: Subscription;
  private sub2: Subscription;
  private selOpened = false;
  promoItem: MenuItem;
  customerNotice: UserSpecificCustomerNotice;
  visibleRootCategories: MenuCategory[];
  visibleSections: MenuSection[];
  emptyMenu = false;

  constructor( private appStateService: AppStateService, private route: ActivatedRoute, private bottomSheet: MatBottomSheet,
               private api: AuthorizedApiService, private router: Router, private ngZone: NgZone, private translate: TranslateService) { }

  ngOnInit(): void {
    console.log("MenuPageComponent init");
    this.beginObserving();
  }

  private loadParams(pm: ParamMap) {
    this.pageId = pm.get("page_id");
    const sectionId = pm.get("section_id");
    if (sectionId) {
      this.sectionId = decodeURIComponent(sectionId) ;
    } else {
      this.sectionId = null;
    }
    console.log(`pageId = ${this.pageId}`);
    console.log(`sectionId = ${this.sectionId}`);
  }

  private beginObserving() {
    this.sub = combineLatest([
      this.route.paramMap,
      this.appStateService.observeAppState()]
    ).subscribe(([pm, appState]) => {
      this.isLoading = false;
      this.venueId = appState.venueId.toString();
      this.loadParams(pm);
      this.appState = appState;
      const branch = this.pageId === "food" ? appState.menu.food : appState.menu.drinks;
      if (this.sectionId) {
        const section = branch.find(s => s.id === this.sectionId);
        this.sections = undefined;
        this.selectedSection = section;
        this.root = undefined;
      } else {
        this.sections = branch;
        this.selectedSection = undefined;
        this.root = this.sections.find( s => s.id === '__root');
      }
      this.visibleRootCategories = this.filterRootCategories();
      this.visibleSections = this.filterSections();
      this.emptyMenu = this.sectionId == null && !this.hasVisibleSections() && !this.hasVisibleRootCategories();
      const pagesWithVisibleItems = this.getPagesWithVisibleItems(appState);
      const tabName = pagesWithVisibleItems.length > 0? this.appStateService.shouldRedirectToOtherTab(this.pageId, appState, this.emptyMenu) : null;
      if (tabName) {
        setTimeout(() => {
          this.ngZone.run(() => this.router.navigateByUrl(`${this.venueId}/menu/${tabName}`));
        });
        return;
      }

      // Open the select dialog if deliver type is not selected
      if (!appState.order.user_cfg.dt) {
        this.openSelectDelivertype();
      }

      const cns = this.appState.order.user_cfg.customer_notices;
      if (cns) {
        this.customerNotice = cns[0];
        this.promoItem = {
          id: "promo",
          name: "Missad betalning",
          desc: this.customerNotice.html,
          formatted: MoneyUtils.format(this.customerNotice.amount)
        } as MenuItem;
      }
    });

    this.sub2 = this.translate.translationsReceivedSubject.subscribe(() => {
      console.log("MenuPageComponent translations received");
      this.visibleSections = this.filterSections();
    });
  }

  getPagesWithVisibleItems(appState: AppState): string[] {
    const pages = [];

    // Food
    const filteredFoodSections = this.filterSections(appState.menu.food);
    const hasVisibleFoodSections = this.hasVisibleSections(filteredFoodSections);
    const rootFoodSections = appState.menu.food.find( s => s.id === '__root');
    const hasVisibleRootFoodCategories = this.filterRootCategories(rootFoodSections);
    if (hasVisibleFoodSections || hasVisibleRootFoodCategories?.length > 0) {
      pages.push("food");
    }

    // Drinks
    const filteredDrinksSections = this.filterSections(appState.menu.drinks);
    const hasVisibleDrinksSections = this.hasVisibleSections(filteredDrinksSections);
    const rootDrinksSections = appState.menu.drinks.find( s => s.id === '__root');
    const hasVisibleRootDrinksCategories = this.filterRootCategories(rootDrinksSections);
    if (hasVisibleDrinksSections || hasVisibleRootDrinksCategories?.length > 0) {
      pages.push("drinks");
    }
    return pages;
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
    this.sub2?.unsubscribe();
    console.log("MenuPage destroyed");
  }

  filterRootCategories(rootCategories = this.root) {
    return rootCategories?.categories.filter( s => !s.mhide );
  }

  filterSections(sections = this.sections) {
    sections = sections?.filter( s => !s.mhide );
    return this.translate.sections(sections);
  }

  openSelectDelivertype(): void {
    if (!this.selOpened) {
      this.selOpened = true;
      const bottomSheetRef = this.bottomSheet.open(SelectDeliverTypeComponent, {data: { cfg: this.appState.cfg, closeable: false }});
      bottomSheetRef.afterDismissed().subscribe(key => { this.selOpened = false; });
    }
  }

  payPromo() {
    this.isLoading = true;
    this.api.createDispReceipt("idfd_not_used", `notice:${this.customerNotice.notice_id}`).subscribe(res => {
      this.openReceiptPayment(res.receipt_key);
    }, null, () => { this.isLoading = false; });
  }

  private openReceiptPayment(receiptKey: string) {
    console.log(receiptKey);
    this.router.navigateByUrl(`${this.appState.venueId}/livesplit/${receiptKey}`);
  }

  openWeekOverview() {
    this.router.navigateByUrl(`${this.appState.venueId}/week`);
  }

  private hasVisibleSections(visibleSections: MenuSection[] = this.visibleSections) {
    if ( visibleSections == null ) {
      //console.log("hasVisibleSections (false) visibleSections == null");
      return false;
    }
    for (const section of visibleSections) {
      if (section.id !== "__root") {
        //console.log("hasVisibleSections (true) section.id " + section.id);
        return true;
      }
    }
    //console.log("hasVisibleSections (false)");
    return false;
  }

  private hasVisibleRootCategories(visibleRootCategories: MenuCategory[] = this.visibleRootCategories) {
    if ( visibleRootCategories == null ) {
      console.log("visibleRootCategories (false) visibleRootCategories == null");
      return false;
    }
    for (const category of visibleRootCategories) {
      if (category.id !== "PROMO") {
        console.log("visibleRootCategories (true) category.id " + category.id);
        return true;
      }
    }
    console.log("visibleRootCategories (false)");
    return false;
  }

}
