<mat-dialog-content class="item-dialog mat-typography">

  <div class="item-content">
    <div class="topLogo">
      <img src="{{typeImage}}" [style.width]="logoWidth">
    </div>
    <div class="payment-button" mat-ripple *ngFor="let btn of buttons" (click)="btnClicked(btn)">
      <div class="tip-button">{{btn.text}}</div>
    </div>
  </div>

  <div class="button-list" style="padding: 16px;margin-top: 32px;">
    <button mat-stroked-button mat-dialog-close>{{getString("Stäng")}}</button>
  </div>

</mat-dialog-content>
