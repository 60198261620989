import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AuthorizedApiService} from '../../services/authorized-api.service';
import {ChargeItem, LivesplitData, LivesplitGroup, Order} from '../../models/Order';
import {filter, first, flatMap, tap} from 'rxjs/operators';
import {UrlHistoryService} from '../../services/url.history.service';
import {combineLatest, from, Observable, of, Subscription} from 'rxjs';
import {PayDialogComponent} from '../pay-dialog/pay-dialog.component';
import {BamboraSession, BamboraTokenizedAuth, VivaPaymentOrder} from '../../models/PaymentModels';
import {OrderService} from '../../services/order.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {VenueConfig} from '../../models/FSConfig';
import {UserType} from '../../objects/Identity';
import {FireService} from '../../services/fire.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import MoneyUtils, {HackUtils, MenuUtils} from '../../utils/Utils';
import {SplitItemDialogComponent} from './split-item-dialog/split-item-dialog.component';
import {MessengerService} from '../../services/messenger.service';
import {AppStateService} from '../../services/app-state.service';
import {AngularFireAuth} from '@angular/fire/auth';
import {SignInDialogComponent} from '../sign-in/sign-in-dialog/sign-in-dialog.component';
import {MenuService} from "../../services/menu.service";
import {MenuItem} from "../../models/FSMenu";
import {TranslateService} from "../../services/translate.service";

@Component({
  selector: 'app-livesplit',
  templateUrl: './livesplit.component.html',
  styleUrls: ['./livesplit.component.css']
})
export class LivesplitComponent implements OnInit, OnDestroy {
  isLoading = true;
  isSending = false;
  nothingToPay: boolean;
  splitType: string;
  allowSplit = false;
  amount: number;
  isAnon?: boolean = null;
  selectedItems = {};
  inlineDonations: any[];
  attrValues = {};
  isMessenger = false;

  data: LivesplitData;
  priceFormatted: string;
  leftFormatted: string;
  groups: LivesplitGroup[];
  left: number;
  lastRid: string;

  private venueId: string;
  private combinedSub: Subscription;
  private order: Order;
  config: VenueConfig;
  private liveSub: Subscription;

  private totalPre: number;
  private itemRefs: any[];
  private receiptKey: string;
  private paymentLinkKey: string;
  private overrideTip: boolean;
  private livesplitTable: string;
  private setupOnce = false;
  private navUrlHack: string;

  constructor(private route: ActivatedRoute, private api: AuthorizedApiService, private identityService: UrlHistoryService,
              private orderService: OrderService, private router: Router, public dialog: MatDialog, private appStateService: AppStateService,
              private fire: FireService, private snackbar: MatSnackBar, private messengerService: MessengerService,
              private firebaseAuth: AngularFireAuth, private menu: MenuService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.init();
    // this.route.paramMap.subscribe(data => {
    //   this.venueId = data.get("venue_id");
    //   const key = data.get("receipt_key");
    //   this.receiptKey = key == null ? undefined : key;
    // });
  }

  ngOnDestroy(): void {
    this.combinedSub?.unsubscribe();
    this.liveSub?.unsubscribe();
  }

  private init() {
    this.combinedSub = combineLatest([
      this.route.paramMap,
      this.appStateService.observeAppState()]
    ).subscribe(res => {
      const pm = res[0];
      const appState = res[1];
      this.venueId = appState.venueId.toString();
      this.order = appState.order;
      this.config = appState.cfg;

      this.isMessenger = this.messengerService.isMessenger(this.order);

      let updated = !this.setupOnce;
      const receiptKey = pm.get("receipt_key");
      const rkey = receiptKey == null ? undefined : receiptKey;
      if (rkey !== this.receiptKey) {
        this.receiptKey = rkey;
        updated = true;
      }
      const paymentLinkKey = pm.get("pl_key");
      const pkey = paymentLinkKey == null ? undefined : paymentLinkKey;
      if (pkey !== this.paymentLinkKey) {
        this.paymentLinkKey = pkey;
        updated = true;
      }

      const isAnon = appState.order.user_cfg.is_anon;
      if (this.isAnon != null && this.isAnon !== isAnon) {
        console.log("User has changed from anon to not anon or vice versa, setting up livesplit again");
        updated = true;
      }
      this.isAnon = isAnon;
      if (updated) {
        this.setupLiveSplitState();
      }
    });
  }

  private setupLiveSplitState() {
    this.liveSub?.unsubscribe();
    this.setupOnce = true;
    this.api.livesplitData(this.receiptKey, this.paymentLinkKey).subscribe(r => {
      this.livesplitTable = r.table;
      console.log('Livesplit data:', r);
      this.splitType = r.split_type;
      if (r.split_type === 'chat' || r.split_type === 'pl') {
        this.isLoading = false;
        this.data = r;
        this.amount = r.amount;
        this.priceFormatted = this.amount.toFixed(2) + ' kr';
        this.nothingToPay = this.amount === 0;
        this.allowSplit = r.allow_split === true;
        this.overrideTip = r.allow_tip === false ? false : null;
      } else if (r.split_type === 'livesplit') {
        this.beginObservingLivesplitData(r.table);
      }
    });

    this.setupDonations();
  }

  public shouldItemBeVisible(item: any): boolean {
    if(item.package) {
      return item.package.aggregated_cent != null;
    }
    return true;
  }

  public getPrice(item: any): number {
    if(item.package && item.package.aggregated_cent != null) {
      return item.package.aggregated_cent / 100;
    }
    return item.price;
  }

  backClicked() {
    this.identityService.navigateBack();
  }

  //////////////////////////
  // LIVESPLIT RELATED
  //////////////////////////

  activateSplit() {
    console.log("Add to split...");
    this.isLoading = true;

    this.api.activateLivesplit(this.paymentLinkKey).subscribe(r => {
      console.log("Activated livesplit successfully");
      this.livesplitTable = r.table;
      this.splitType = "livesplit";
      this.beginObservingLivesplitData(r.table);
    }, error => {
      this.isLoading = false;
    });
  }

  private beginObservingLivesplitData(table: string) {
    console.log(`Observe table: ${table}`);
    this.liveSub = this.fire.observeLivesplit(table, Number(this.venueId)).subscribe(ls => {
      if (ls == null) {
        console.warn("Livesplit data is missing");
        return;
      }
      const groups = this.groupPaymentData(ls);
      this.groups = this.sortGroups(groups, this.order.user_cfg.user_id.toString());
      this.priceFormatted = MoneyUtils.format(this.amount);
      this.leftFormatted = MoneyUtils.format(this.left);
      console.log("Groups:", this.groups);

      this.nothingToPay = false;
      this.isLoading = false;
    });
  }

  private addRowToCurrentSelected(row) {
    const count = row.count ?? 1;
    const priceSum = row.price * count;
    const prePriceSum = (row.pre_price ?? row.price) * count;
    this.amount += priceSum;
    this.totalPre += prePriceSum;
    this.itemRefs.push(`${row.ok}|${row.rid}`);
  }

  private groupPaymentData(data) {
    console.log("Document data:", data);
    const userId = this.order.user_cfg.user_id;
    console.log("this.userId:", userId);

    this.amount = 0;
    this.totalPre = 0;
    this.left = 0;
    this.itemRefs = [];
    const groups = [];
    const groupCount = data.groups;
    for (let i = 0; i < groupCount; i++) {
      const group = data["group" + i];
      const rids = group.rids.split(",");
      const items = [];
      let allSelected = true;
      for (const rid of rids) {
        const row = data["rid" + rid];
        const isMine = userId === row.user_id;
        if (row.state === "paid") {
          row.canChange = false;
        } else {
          if (row.state === "reserved") {
            row.canChange = isMine;
            if (isMine) {
              this.addRowToCurrentSelected(row);
            }
          } else {
            const count = row.count ?? 1;
            const priceSum = count * row.price;
            this.left += priceSum;
            row.canChange = true;
            allSelected = false;
          }
        }

        row.selected = row.state === "paid" || row.state === "reserved";
        items.push(row);
      }
      group.selected = allSelected;
      group.items = items;
      group.jointNote = group.user_id.includes("-");
      group.isMine = group.user_id === userId.toString();
      groups.push(group);
    }
    console.log("Grouped data", groups);
    return groups;
  }

  itemSelected(item: any) {
    console.log("itemSelected", item);
    this.isSending = true;
    const docRef = this.fire.getLivesplitDocRef(this.livesplitTable, Number(this.venueId)).ref;
    const affectedItems = this.groups.flatMap(g => g.items.filter(i => {
      // Check if the item's package is not null and matches the selected item's package pid
      // or if the item's package is null, check if the item's rid matches the selected item's rid
      return (item.package && i.package && item.package.pid === i.package.pid) || (!item.package && i.rid === item.rid);
    }));

    console.log("Affected Items", affectedItems);
    this.fire.runTransaction(transaction => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then( doc => {
        if (!doc.exists) {
          this.isSending = false;
          throw new Error("Document does not exist!");
        }
        // Do for all affected items
        const data = doc.data();
        const d = {};
        for (const affectedItem of affectedItems) {

          const rowName = "rid" + affectedItem.rid;
          const orgRowData = data[rowName];
          const reserve = item.selected;

          //Check has correct state
          const orgIsOpen = orgRowData.state == null || orgRowData.state === "open";
          const orgIsReserved = orgRowData.state === "reserved";
          if (reserve && !orgIsOpen || !reserve && !orgIsReserved) {
            console.log("Race condition, and you lost!");
            transaction.update(docRef, {});
            this.snackbar.open("Oj den hann bli reserverad!", null, { duration: 2000 });
            return;
          }

          if (reserve) {
            orgRowData.state = "reserved";
            orgRowData.user_id = this.order.user_cfg.user_id;
            orgRowData.user = this.order.user_cfg.user_name;
          } else {
            orgRowData.state = "open";
            orgRowData.user_id = 0;
            orgRowData.user = "";
          }

          d[rowName] = orgRowData;
        }
        transaction.update(docRef, d);
      });
    }).then( () => {
      if (item.selected) {
        this.lastRid = item.rid;
      }
      this.isSending = false;
      console.log("Transaction successfully committed!");
    }).catch( error => {
      this.snackbar.open("Gick inte att reservera artikeln", null, { duration: 2000 });
      this.isSending = false;
      item.selected = !item.selected;
      console.log("Transaction failed: ", error);
    });
  }

  selectAll(group: LivesplitGroup) {
    console.log("selectAll", group);
    this.isSending = true;
    const docRef = this.fire.getLivesplitDocRef(this.livesplitTable, Number(this.venueId)).ref;

    this.fire.runTransaction(transaction => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(docRef).then( doc => {
        if (!doc.exists) {
          this.isSending = false;
          throw new Error("Document does not exist!");
        }

        const d = {};
        for (const item of group.items) {
          const rowName = "rid" + item.rid;
          const data = doc.data();
          const orgRowData = data[rowName];

          //Check has correct state
          const orgIsOpen = orgRowData.state == null || orgRowData.state === "open";
          if (!orgIsOpen) {
            continue;
          }

          orgRowData.state = "reserved";
          orgRowData.user_id = this.order.user_cfg.user_id;
          orgRowData.user = this.order.user_cfg.user_name;
          d[rowName] = orgRowData;
        }

        transaction.update(docRef, d);
      });
    }).then( () => {
      this.isSending = false;
      console.log("Transaction successfully committed!");
    }).catch( error => {
      this.snackbar.open("Gick inte att reservera", null, { duration: 2000 });
      this.isSending = false;
      console.log("Transaction failed: ", error);
    });
  }

  splitItems(item: any) {
    const affectedItems = this.groups.flatMap(g => g.items.filter(i => {
      // Check if the item's package is not null and matches the selected item's package pid
      // or if the item's package is null, check if the item's rid matches the selected item's rid
      return (item.package && i.package && item.package.pid === i.package.pid) || (!item.package && i.rid === item.rid);
    }));

    console.log("splitItems", item, affectedItems);
    this.openSplitItemsDialog(item, affectedItems).subscribe( btn => {
      console.log("Selected btn:", btn);
      if (btn) {
        this.isSending = true;
        const itemsToSplit = affectedItems.map(i => ({ orderKey: i.ok, rid: i.rid }));
        this.api.splitItems(itemsToSplit, btn.parts, this.paymentLinkKey).subscribe( r => {
          this.isSending = false;
        }, error => {
          console.log("Gick inte att dela", error);
          this.snackbar.open("Gick inte att dela", null, { duration: 2000 });
          this.isSending = false;
        });
      }
    });
  }

  openSplitItemsDialog(item: any, affectedItems: any[]): Observable<any> {
    const dialogRef = this.dialog.open(SplitItemDialogComponent, HackUtils.DLG({
      data: { item, affectedItems }
    }));
    return dialogRef.afterClosed();
  }

  //////////////////////////
  // PAYMENT RELATED
  //
  // TODO DRY this! Code is repeated
  //  in cart component, this should be fixed.
  //////////////////////////
  pay(type: string) {
    if (this.amount <= 0) { return; }
    const payFullReceiptKey = this.splitType !== "livesplit" ? this.data?.receipt_key : undefined;
    const chargeItems = this.gatherChargeItems();
    this.openPayDialog(type).pipe(
      filter( res => res ),
      flatMap( res => {
        this.isLoading = true;
        switch (type) {
          case "card":
            console.log(`Pay dialog: ${res.selected} (tip ${res.tip})`);
            if (res.selected === "saved_card") {
              const declineRoute = this.receiptKey ? `livesplit/${this.receiptKey}` : "livesplit";
              return this.api.chargeSavedCard(res.tip, payFullReceiptKey, this.itemRefs, declineRoute, HackUtils.getBrowserInfo(), this.paymentLinkKey, undefined, chargeItems).pipe(
                tap( bamTok => {
                  if (bamTok.method !== "noredirect") {
                    this.redirectSCA(bamTok);
                  }
                })
              );
            } else {
              return this.api.createBamboraSession(res.tip, payFullReceiptKey, this.itemRefs, this.paymentLinkKey, undefined, chargeItems, undefined, "livesplit").pipe(
                flatMap( session => this.openBamboraCheckout(session) )
              );
            }
          case "card_gp":
            return this.api.createBamboraSession(res.tip, payFullReceiptKey, this.itemRefs, this.paymentLinkKey, undefined, chargeItems, "google_pay", "livesplit").pipe(
              flatMap( session => this.openBamboraCheckout(session) )
            );
          case "viva_smart":
            console.log(`Pay viva_smart dialog: ${res.selected} (tip ${res.tip})`);
            const loc = this.router.url;
            return this.api.createVivaPaymentOrder(res.tip, payFullReceiptKey, this.itemRefs, this.paymentLinkKey, undefined, undefined, loc).pipe(
              flatMap(vpo => this.openVivaSmartCheckout(vpo))
            );
          case "swish":
            console.log(`Pay dialog: ${res} (tip ${res.tip})`);

            return from(this.firebaseAuth.currentUser).pipe(
              flatMap( user => {
                // TODO this hack needs to be generalized, now diffrent between /livesplit and /cart
                let feedUrlHack = window.location.href;
                let index = 0;
                if (window.location.href.includes("/livesplit")) {
                  index = window.location.href.indexOf("/livesplit");
                } else {
                  index = window.location.href.indexOf("/pl/");
                }
                if (index>0) {
                  feedUrlHack = window.location.href.substring(0, index) + `/paystatus/RECEIPT_KEY?sec=_SECURITY_&uid=${user.uid}`;
                }
                console.log(feedUrlHack);
                const href = this.order.user_cfg.user_type === UserType.Messenger ? undefined : feedUrlHack;
                return this.api.swishLink(res.tip, href, payFullReceiptKey, this.itemRefs, this.paymentLinkKey, undefined, chargeItems);
              }),
              tap( sl => {
                this.navUrlHack = null;
                // Redirect to app will fail on desktop in messenger
                if (this.messengerService.isOnDesktopAndInIFrame()) {
                  this.router.navigateByUrl(`${this.venueId}/paystatus/${res.receipt_key}`);
                } else {
                  // Patch link with receipt key
                  const link = sl.link.replace("RECEIPT_KEY", sl.receipt_key);
                  console.log("redirect to: " + link);
                  window.location.replace(link);

                  // Build pre navigate url to make sure we open same tab in mobile browsers
                  const urlparts = decodeURIComponent(link).split("/paystatus/");
                  if (urlparts.length === 2) {
                    const navUrl = `${this.venueId}/paystatus/${urlparts[1]}`;
                    this.navUrlHack = navUrl;
                  }
                }
              })
            );
        }
      }),
      tap( res => {
        this.isLoading = false;
        console.log(res);
        if ( res.receipt_key ) {
          // Payment initiated waiting for external confirmation (swish payment, 3D-secure...)
          if (this.navUrlHack != null) {
            console.log("nav to: " + this.navUrlHack);
            this.router.navigateByUrl(this.navUrlHack);
          } else {
            this.router.navigateByUrl(`${this.venueId}/paystatus/${res.receipt_key}`);
          }
        } else {
          this.orderService.clearCurrentOrder();
          this.messengerService.doPostAction(this.venueId, "cart_paid");
        }
      })
    ).subscribe( res => {
    }, error => {
      this.isLoading = false;
      const s = this.getString("Tyvärr kunde vi inte genomföra betalningen.");
      this.snackbar.open(s, this.getString("Stäng"), { duration: 20000 });
      console.log("Gick inte att betala", error);
    } );
  }

  private redirectSCA(bamTok: BamboraTokenizedAuth) {
    if (bamTok.method === "post") {
      console.log("Redirect with POST");
      let inputs = "";
      for (const param of bamTok.parameters) {
        inputs += `<input name="${param.name}" type="hidden" value="${param.value}" />`;
      }
      const html = `<form id="innerFormPostLS" method="post" action="${bamTok.redirect_url}">${inputs}</form>`;
      document.getElementById("formPostDivLS").innerHTML = html;
      console.log(html);
      const innerFormPost = document.getElementById("innerFormPostLS");
      console.log(innerFormPost);
      if (innerFormPost) {
        // @ts-ignore
        innerFormPost.submit();
      }
    } else if (bamTok.method === "redirect") {
      console.log("Redirect to", bamTok.redirect_url);
      window.location.replace(bamTok.redirect_url);
    }
  }

  private openPayDialog(type: string): Observable<any> {
    const showTip = this.overrideTip == null ? this.order.user_cfg.payment.use_tip : this.overrideTip;
    const isCardAndHasSavedCard = type === "card" && this.order.user_cfg.payment?.saved_card;
    const needToOpen = type !== "prepaid" && ( isCardAndHasSavedCard || showTip);
    if (needToOpen) {
      const dialogRef = this.dialog.open(PayDialogComponent, HackUtils.DLG({
        data: { type, userCfg: this.order.user_cfg, amount: this.amount, overrideTip: this.overrideTip }
      }));
      return dialogRef.afterClosed();
    } else {
      return from([{tip: 0}]);
    }
  }

  private openBamboraCheckout(session: BamboraSession): Observable<any> {
    console.log(`Open bambora checkout: ${session}`);
    return new Observable( obs => {
      // @ts-ignore
      const checkout = new Bambora.RedirectCheckout(session.token);
    });
  }

  private openVivaSmartCheckout(vpo: VivaPaymentOrder): Observable<any> {
    console.log(`Open viva smart checkout: ${vpo}`);
    return new Observable(obs => {
      console.log("redirect to: " + vpo.redirect_url);
      window.location.replace(vpo.redirect_url);
    });
  }

  terms() {
    this.router.navigateByUrl(`${this.venueId}/terms`);
    return false;
  }

  login() {
    console.log("login...");
    const dialogRef = this.dialog.open(SignInDialogComponent, HackUtils.DLG({
      data: {}
    }));
  }

  private setupDonations() {
    if (this.receiptKey != null) {
      // Dont show donations if we are on a refill page
      return;
    }
    const menuStruct = this.menu.getMenuStructure();
    if (menuStruct.donation_items?.length > 0) {
      this.inlineDonations = menuStruct.donation_items;
    }
  }

  selectedChange(selectedItem: MenuItem) {
    // Setup default attribute values
    this.attrValues[selectedItem.id] = {};
    for (const atr of selectedItem.attributes ?? []) {
      if (!atr.hidden && !this.attrValues[selectedItem.id][atr.id]) {
        const opt = atr.options.find(o => o.default) ?? (atr.options.length > 1 ? atr.options[0] : undefined);
        if (opt) {
          this.attrValues[selectedItem.id][atr.id] = opt.key;
        }
      }
    }
  }

  private gatherChargeItems(): ChargeItem[] | undefined {
    const menuStruct = this.menu.getMenuStructure();
    const itemIds = MenuUtils.collectSelectedItemIds(this.selectedItems, this.attrValues, menuStruct);
    if (itemIds.length === 0) {
      return undefined;
    }
    const chargeItems = itemIds.map( itemId => ({item_id: itemId}) );
    console.log("Collected charge items", chargeItems);
    return chargeItems;
  }

  getString(s: string, pc: string = "normal") {
    return this.translate.single(s, pc);
  }

  private sortGroups(groups: any[], userId: string): any[] {
    // Sort first groups with jointNote === True
    // Then group where isMine === True
    // Then the rest by name

    const groupsWithJointNote = groups.filter(g => g.jointNote);
    // update name to "Gemensam nota" if theres only one group
    // update name to "Gemensam nota ({old name})" if there is more than one group
    if (groupsWithJointNote.length === 1) {
      groupsWithJointNote[0].name = this.getString("Gemensam nota");
    } else {
      for (const group of groupsWithJointNote) {
        group.name = this.getString("Gemensam nota") + ` (${group.name})`;
      }
    }

    const guestCount = groups.length - groupsWithJointNote.length;
    //Set hint to "Beställningar som du delar med andra"
    for (const group of groupsWithJointNote) {
      if (guestCount < 2) {
        group.hint = this.getString("Beställningar som personalen gjort");
      } else {
        group.hint = this.getString("Beställningar som du delar med andra");
      }
    }

    const groupsWithUser = groups.filter(g => g.isMine);
    //Set hint to "Beställnignar som du gjort"
    for (const group of groupsWithUser) {
      group.hint = this.getString("Beställningar som du gjort");
    }

    const groupsWithoutUser = groups.filter(g => !g.jointNote && g.user_id !== userId);

    groupsWithJointNote.sort((a, b) => a.name.localeCompare(b.name));
    groupsWithUser.sort((a, b) => a.name.localeCompare(b.name));
    groupsWithoutUser.sort((a, b) => a.name.localeCompare(b.name));
    return groupsWithJointNote.concat(groupsWithUser).concat(groupsWithoutUser);
  }
}
