import { Injectable, NgZone } from '@angular/core';
import {filter, flatMap, tap} from 'rxjs/operators';
import { ActivationStart, Router } from '@angular/router';
import { Identity } from '../objects/Identity';
import { AngularFireAuth } from '@angular/fire/auth';
import {combineLatest, from, Observable, ReplaySubject, Subscription} from 'rxjs';
import { ApiService } from './api.service';
import { OrderService } from './order.service';
import { auth } from 'firebase';
import { AuthorizedApiService } from './authorized-api.service';
import {MessengerService} from './messenger.service';
import {Order, UserSpecificConfig} from "../models/Order";
import { ConfigService } from './config.service';
import { VisibilityService } from './visibility.service';
import firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  identity: Identity;
  private firebaseUser: firebase.User;
  venueId: string;
  signInProgress = false;
  private messengerSuccessCount = 0;
  private identitySubject = new ReplaySubject<Identity>(1);
  private tokenSubject: ReplaySubject<{user: firebase.User, tokenVenueId?: string}> = new ReplaySubject(1);
  private cfgSub: Subscription;
  private recaptchaVerifier: firebase.auth.RecaptchaVerifier;
  private confirmationResult: firebase.auth.ConfirmationResult;

  // firebaseApp ref seemed to fix an strange issue in mobile safari
  public kioskMode: boolean;
  private kioskState: string;
  constructor(private route: Router, private firebaseAuth: AngularFireAuth, private authApiService: AuthorizedApiService,
              private apiService: ApiService, private orderService: OrderService,
              private ngZone: NgZone, private messengerService: MessengerService, private configService: ConfigService,
              private visibilityService: VisibilityService) {
    this.init();
  }

  private getMlLinkKey(venueId: string) {
    return `ml_link_${venueId}`;
  }

  private init(): void {
    console.log("XXXXX init AuthService...");
    this.kioskMode = localStorage.getItem("kiosk_mode") === "true";
    this.kioskState = localStorage.getItem("kiosk_state") || "standard";
    this.setupTokenListner();

    combineLatest([
      this.route.events.pipe(filter((event: ActivationStart) => event instanceof ActivationStart)),
      this.tokenSubject,
      this.visibilityService.visibilityChange$]
    ).subscribe(res => {
      const event = res[0];
      const userTup = res[1];
      // visibility checks if the browser tab is visible or not. This prevents auth logic from running in parallell on multiple tabs.
      const visibility = res[2];
      if (!visibility) {
        console.log("XXXXX visibility hidden, skip auth logic...");
        return;
      }
      let userType = event.snapshot.queryParamMap.get("ut");
      let user = userTup.user;
      if (userTup?.user?.uid.startsWith("fbm_") && userType === "web") {
        this.logout();
        return;
      }
      const tokenVenueId = userTup.tokenVenueId;

      console.log(`Auth url: ${event.snapshot.url}`);
      console.log(`Auth uid: ${user?.uid}, anonymous: ${user?.isAnonymous}`);
      console.log(`tokenVenueId: ${tokenVenueId}`);

      //1. venue change
      //2. ml_link change from null to a value
      //3. user changed to not null (logged in)
      //4. user changed to null (logged out)
      //5. user changed (new token)
      //6. User signed in with new/changed table_code
      //(other changes should not trigger run of logic)

      const venueId = event.snapshot.paramMap.get("venue_id");
      const venueChanged = this.venueId !== venueId;
      this.venueId = venueId;
      console.log("Venue id: ", this.venueId);
      const tableCode = event.snapshot.queryParamMap.get("table");
      const tableCodeChanged = tableCode != null;
      let ml = event.snapshot.queryParamMap.get("ml");
      const mlLink = localStorage.getItem(this.getMlLinkKey(this.venueId));

      console.log(`mllink localstorage: ${mlLink}`);
      if (ml != null && tokenVenueId !== venueId) {
        console.log("XXXXX got ml! and tokenVenueId != venueId");
        user = null;
      } else if (ml == null && user == null && mlLink != null) {
        console.log(`got ml link ${ml} and user null, and ml is null, should perform messengerSignin`);
        ml = mlLink;
      } else if (ml != null && user != null) {
        console.log(`got ml link ${ml} and user is not null, should perform serverSignin`);
      } else if (ml != null && user === null) {
        console.log(`got ml link ${ml} and no user, should perform messengerSignin`);
      }

      const firebaseUserHasChanged = this.firebaseUser?.uid !== user?.uid;
      const oldFirebaseUser = this.firebaseUser;
      this.firebaseUser = user;

      this.identity = new Identity();
      this.identity.venueId = venueId;
      this.authApiService.setIdentity(this.identity);

      const urlSec = event.snapshot.queryParamMap.get("sec");
      const urlUid = event.snapshot.queryParamMap.get("uid");
      const urlUidNotSameAsLoggedInUser = urlUid != null && urlUid !== this.firebaseUser?.uid;
      if (urlSec != null && urlUidNotSameAsLoggedInUser) {
        console.log(`XXXXX urlUid ${urlUid} !== firebase user uid ${this.firebaseUser?.uid}`);
        console.log(`Clearing user"`, user);
        user = null;
      }

      if (user != null && firebaseUserHasChanged) {
        this.performServerSigninActions(this.venueId, oldFirebaseUser, tableCode);
      } else {
        if (ml != null) {
          this.signInMessenger(ml);
        } else if (urlSec != null && urlUidNotSameAsLoggedInUser) {
          this.signInUrlToken(urlSec, urlUid);
        } else if (this.firebaseUser == null) {
          this.signInAnonymously();
        } else if (venueChanged) {
          this.orderService.fetchCurrentOrder(tableCode);
        } else if (tableCodeChanged) {
          this.orderService.changeTable(tableCode);
        } else {
          this.assureKioskMode();
        }
      }
      this.observeVenueConfig();
    });
  }

  ngOnDestroy(): void {
    this.cfgSub?.unsubscribe();
  }

  private observeVenueConfig() {
      this.cfgSub = this.configService.observeConfig(this.venueId).subscribe(config => {
        this.kioskState = config?.kiosk_state;
      });
  }

  private setupTokenListner() {
    this.firebaseAuth.onAuthStateChanged(user => {
      console.log("XXXXX onAuthStateChanged");
      if (user != null) {
        user.getIdTokenResult().then((idTokenResult) => {
          console.log(idTokenResult);
          const tokenVenueId = idTokenResult?.claims?.venue_id?.toString();
          this.tokenSubject.next({user, tokenVenueId});
        }).catch((error) => {
          console.log(error);
        });
      } else {
        this.tokenSubject.next({user});
      }
    });
  }

  private signInAnonymously() {
    if (this.signInProgress) {
      console.log("XXXXX skip sign in anon, since we are already processing another sign in...");
      return;
    }
    console.log("XXXXX signInAnonymously...");
    localStorage.removeItem(this.getMlLinkKey(this.venueId));
    this.signInProgress = true;
    this.firebaseAuth.signInAnonymously().then(userCred => {
      console.log(userCred);
    }).catch(error => {
      console.log(error);
    }).finally(() => {
      this.setProgressToFalse();
    });
  }

  private setProgressToFalse() {
    this.ngZone.run(() => {
      this.signInProgress = false;
    });
  }

  private signInMessenger(ml: string) {
    console.log("XXXXX signInMessenger..." + this.messengerSuccessCount);
    if(this.messengerSuccessCount > 10) {
      this.preventSpammyPage();
      return;
    }
    this.apiService.getCustomTokenForMessenger(ml).pipe(flatMap(token => {
      this.messengerSuccessCount += 1;
      localStorage.setItem(this.getMlLinkKey(this.venueId), ml);
      return from(this.firebaseAuth.signInWithCustomToken(token));
    })).subscribe(t => {
      console.log(`Signed in to firebase with custom token ${t.user.uid}`);
    });
  }

  private signInUrlToken(urlSec: string, uid: string) {
    console.log("XXXXX signInUrlToken...");
    return this.apiService.getCustomTokenForUrlToken(urlSec, uid).pipe(flatMap(token => {
      console.log(`Got custom token ${token}`);
      return from(this.firebaseAuth.signInWithCustomToken(token));
    })).subscribe(t => {
      console.log(t);
    }, e => {
      console.log("Error", e);
    });
  }

  private performServerSigninActions(venueId: string, oldFirebaseUser: firebase.User | null, tableCode: string | null) {
    console.log("XXXXX performServerSigninActions...");
    return this.apiService.signInFromFirebase(this.firebaseUser, tableCode, venueId, oldFirebaseUser).subscribe(order => {
      console.log(`Got server order for user ${order.user_cfg?.user_id}`);
      const tokenVenueId = order.venue_id.toString();
      if (order.user_cfg.user_type === "messenger" && tokenVenueId !== venueId && this.messengerService.getMessengerState() === "OUTSIDE") {
        console.log(`Got server order for user ${order.user_cfg?.user_id}`);
        console.log(`${tokenVenueId} != ${venueId}`);
        this.reloadWithNewVenueId(tokenVenueId);
        return;
      }
      this.orderService.setState(order);
      this.identitySubject.next(this.identity);
    }, error => { }, () => { this.setProgressToFalse(); });
  }

  public async logout(persistIdentity = false, manuallySignedOut = true): Promise<void> {
    localStorage.removeItem(this.getMlLinkKey(this.venueId));
    await this.firebaseAuth.signOut();
  }

  public async googleAuth(): Promise<void> {
    this.signInWithProvider("google");
  }

  public async appleAuth(): Promise<void> {
    this.signInWithProvider("apple");
  }

  private getProviderObject(provider: string): auth.OAuthProvider | auth.GoogleAuthProvider {
    let p: auth.OAuthProvider | auth.GoogleAuthProvider;
    if (provider === "google") {
      p = new auth.GoogleAuthProvider();
    } else if (provider === "apple") {
      p = new auth.OAuthProvider('apple.com');
    }
    return p;
  }

  private async signInWithProvider(provider: string): Promise<void> {
    try {
      console.log("About to sign in with provider:", provider);
      this.signInProgress = true;
      const authProvider = this.getProviderObject(provider);
      //p.setCustomParameters({ prompt: "select_account" });
      await this.firebaseAuth.signInWithPopup(authProvider);
      console.log("Signed in");
    } catch (error) {
      this.setProgressToFalse();
      console.log("Provider sign in failed... Error:", provider, error);
    }
  }

  public observeIdentity(): ReplaySubject<Identity> {
    return this.identitySubject;
  }

  private preventSpammyPage() {
    console.log("Page seems stuck in a loop, putting it out of its misery...");
    window.location.href="about:blank";
  }

  private reloadWithNewVenueId(venueId: string) {
    console.log("Reload with new venueId....");
    const index = window.location.href.indexOf("/", 10);
    const url = `${window.location.href.substring(0, index)}/${venueId}/menu/food`;
    console.log(url);
    window.location.replace(url);
  }

  activateKioskMode(): Observable<Order> {
    const table = this.orderService.getOrderOrNull()?.user_cfg.table;
    return this.setupKioskModeForUser(table);
  }

  deactivateKioskMode(): Observable<Order> {
    const table = this.orderService.getOrderOrNull()?.user_cfg.table;
    return this.setupKioskModeForUser("0");
  }

  private assureKioskMode() {
    if (this.kioskMode) {
      //console.log(`Kiosk mode detected, validate state...`);
      const kt = localStorage.getItem("kiosk_table");
      const userCfg = this.orderService.getOrderOrNull()?.user_cfg;
      if (kt && userCfg && (userCfg.kiosk_mode !== true || userCfg.table !== kt || userCfg.must_scan)) {
        console.log(`Kiosk mode detected, state is not correct, setup kiosk mode...`);
        this.setupKioskModeForUser(kt).subscribe(value => {});
      }
    }
  }

  // Setup kiosk mode for user at server, table not null => active kiosk mode
  private setupKioskModeForUser(table: string): Observable<Order> {
    return this.configService.getNonEmptyConfig().pipe(flatMap(cfg => this.doSetupKioskModeForUser(table)));
  }

  // Setup kiosk mode for user at server, table not null => active kiosk mode
  private doSetupKioskModeForUser(table: string): Observable<Order> {
    return this.orderService.changeTable(undefined, table).pipe(tap(() => {
      if (table === "0") {
        console.log(`Disable kiosk mode for user`);
        localStorage.removeItem("kiosk_mode");
        localStorage.removeItem("kiosk_table");
        localStorage.removeItem("kiosk_state");
        this.kioskMode = false;
      } else {
        console.log(`Setup kiosk mode for user at table ${table}`);
        localStorage.setItem("kiosk_mode", "true");
        localStorage.setItem("kiosk_table", table);
        localStorage.setItem("kiosk_state", this.kioskState)
        this.kioskMode = true;
      }
    }));
  }

  isAnonOrderable(userConfig?: UserSpecificConfig): boolean {
    const ao = userConfig?.is_anon_orderable;
    return ao === true || this.kioskMode;
  }

  public isKioskMode(): boolean {
    return this.kioskMode;
  }

  public getKioskState(): string {
    return this.kioskState || "standard";
  }

  public initializeRecaptcha(containerId: string): void {
    console.log("Initializing reCAPTCHA...");
    this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(containerId, {
      size: 'invisible', // Use 'normal' if you want the reCAPTCHA to be visible
      callback: (response) => {
        console.log('reCAPTCHA solved');
      },
      'expired-callback': () => {
        console.log('reCAPTCHA expired');
        this.recaptchaVerifier.clear();
      },
    });
    console.log(`reCAPTCHA initialized ${this.recaptchaVerifier}`);
  }

  public sendVerificationCode(phoneNumber: string): Promise<void> {
    return this.firebaseAuth.signInWithPhoneNumber(phoneNumber, this.recaptchaVerifier)
      .then((confirmationResult) => {
        this.confirmationResult = confirmationResult;
        console.log('Verification code sent to', phoneNumber);
      });
  }

  public verifyCode(code: string): Promise<void> {
    return this.confirmationResult.confirm(code)
      .then((result) => {
        const user = result.user;
        console.log('User signed in successfully:', user);
        // Perform any additional actions with the signed-in user
      });
  }
}
