<main>
  <section *ngIf="config">
    <div  *ngIf="!isLoading" class="cart-container">
      <div class="cart-back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon> <div style="margin: 3px 0 0 5px;">{{getString("Varukorg", "upper")}}</div>
      </div>

      <div *ngIf="hasItems()" class="collect-panel">
        <div *ngFor="let item of editableItems; let i = index; let last = last;">
          <div class="list-item" (click)="openItemDialog(item)" mat-ripple [matRippleDisabled]="item.included">
            <div>
              <div [ngClass]="{'item-name':!item.included, 'item-name-included':item.included}"><span *ngIf="item.count > 1" style="font-weight: normal;">{{item.count}} x </span>{{getString(item.name)}}</div>
              <div>{{item.customized}}</div>
              <div>{{item.comment}}</div>
            </div>
            <div class="cart-price" *ngIf="!item.included">{{item.formatted_item_total}}</div>

          </div>
        </div>

        <div class="item-separator"></div>

        <div *ngFor="let item of fixedItems; let i = index; let last = last;">
          <div class="list-item">
            <div>
              <div><span *ngIf="item.count > 1" style="font-weight: normal;">{{item.count}} x </span>{{item.name}}</div>
              <div>{{item.customized}}</div>
            </div>
            <div class="cart-price">{{item.formatted_item_total}}</div>
          </div>
        </div>

        <div class="item-summary">
          <div *ngIf="order.discount" class="summary-row" style="margin-bottom: 8px;font-size: 14px;">
            <div>{{getString("Rabatt")}}</div><div>-{{order.discount_formatted}}</div>
          </div>
          <div class="summary-row">
            <div style="font-weight: bold;">{{getString("Totalt")}} <span style="font-weight: normal;font-size: 13px;">({{getString("inkl. moms")}})</span></div><div>{{order.total}}</div>
          </div>

        </div>

      </div>

      <div class="collect-panel" *ngIf="payableItems?.length > 0">
        <div class="collect-panel-title">
          <mat-icon style="font-size: 16px; width: 16px;">liquor</mat-icon>
          <div style="margin-left: 4px;">
            <div class="under">Tidigare beställningar</div>
          </div>
        </div>
        <div>
          <div *ngFor="let item of payableItems; let i = index; let last = last;">
            <div class="list-item">
              <div style="width: 100%">
                <mat-checkbox class="clickable-area" [disabled]="!item.canChange" [(ngModel)]="item.selected" (change)="itemSelected(item)">
                  <div><b *ngIf="!item.parts && item.count>1">{{item.count}}x&nbsp;</b><b *ngIf="item.parts">{{item.count}}/{{item.parts}}&nbsp;</b>{{item.name}} - <i *ngIf="item.comment" style="color: #888;font-size: 15px;"> {{item.comment}} - </i><i style="color: #888;font-size: 15px;"> {{item.price}} kr</i></div>
                </mat-checkbox>
                <div [ngClass]="{'mine':item.canChange,'others':!item.canChange}" *ngIf="item.state === 'reserved'">Reserverad <span *ngIf="!item.canChange">av {{item.user}}</span><span *ngIf="item.canChange">av dig</span></div>
                <div [ngClass]="{'mine':item.canChange,'others':!item.canChange}" *ngIf="item.state === 'paid'">Betalad <span *ngIf="item.user_id != 0">av {{item.user}}</span></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isDTOpen() && hasItems() === false" class="collect-panel">
        <div class="collect-panel-title">
          <mat-icon style="font-size: 16px; width: 16px;">info</mat-icon>
          <div style="margin-left: 4px;">
            <div class="under">{{getString("Info")}}</div>
          </div>
        </div>
        <div>{{getString("Du har inte lagt till något i din beställning ännu. Välj mat och dryck från menyn.")}}</div>
      </div>

      <div *ngIf="inlineRecommendations" class="collect-panel">
        <div class="collect-panel-title">
          <mat-icon style="font-size: 16px; width: 16px;">star</mat-icon>
          <div style="margin-left: 4px;">
            <div class="under">{{getString("Passar bra till")}}</div>
          </div>
        </div>
        <div>
          <div *ngFor="let item of inlineRecommendations; let lastit = last;" class="push-item">
            <mat-checkbox class="clickable-area" [(ngModel)]="selectedItems[item.id]" (change)="selectedChange(item)">
              <div class="push-name">{{getString(item.name)}} - {{item.formatted}}</div>
            </mat-checkbox>
            <div style="padding-left: 33px">
              <div *ngIf="item.desc" class="desc">{{getString(item.desc)}}</div>
              <div class="item-contains" *ngIf="item.contains">
                {{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of item.contains; let last = last;">{{getString(con.name,"upper")}}{{last ? "" : ", "}}</span>
              </div>
              <div *ngIf="selectedItems[item.id]">
                <div *ngFor="let attribute of item.attributes | filterHiddenAtr; let atri = index; let lastatr = last;">
                  <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[item.id][attribute.id]">
                    <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
                      <mat-radio-button [value]="option.key">
                        <div><span class="atr-name">{{option.value}}</span><span> {{option.formatted}}</span></div>
                        <div class="item-contains-sm" *ngIf="option.contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of option.contains; let last = last;">{{getString(con.name,"upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
                      </mat-radio-button>
                    </label>
                  </mat-radio-group>
                  <mat-checkbox class="clickable-area" *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[item.id][attribute.id]">
                    <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
                    <div class="item-contains-sm" *ngIf="attribute.options[0].contains">{{getString("INNEHÅLLER", "upper")}} <span *ngFor="let con of attribute.options[0].contains; let last = last;">{{getString(con.name,"upper")}}{{con.del ? "*" : ""}}{{last ? "" : ", "}}</span></div>
                  </mat-checkbox>
                  <div class="item-separator" style="margin-top: 15px"></div>
                </div>
              </div>
            </div>
            <div *ngIf="!lastit" class="item-separator"></div>
          </div>
        </div>
      </div>

      <div *ngIf="inlineDonations && isDTOpen() && hasItems() && !order.user_cfg.payment.open_order && !mustScan()" class="collect-panel">
        <div class="collect-panel-title">
          <mat-icon style="font-size: 16px; width: 16px;color:red;">favorite</mat-icon>
          <div style="margin-left: 4px;">
            <div class="under">{{translate.single("Gåva")}}</div>
          </div>
        </div>
        <div>
          <div *ngFor="let item of inlineDonations; let lastit = last;" class="push-item">
            <mat-checkbox class="clickable-area" [(ngModel)]="selectedItems[item.id]" (change)="selectedChange(item)">
              <div class="push-name">{{item.name}}</div>
            </mat-checkbox>
            <div style="padding-left: 33px">
              <div *ngIf="item.desc" class="desc">{{item.desc}}</div>
              <div *ngIf="selectedItems[item.id]">
                <div *ngFor="let attribute of item.attributes | filterHiddenAtr; let atri = index; let lastatr = last;">
                  <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[item.id][attribute.id]">
                    <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
                      <mat-radio-button [value]="option.key">
                        <div><span class="atr-name">{{option.value | attributeCutter}}</span><span> {{option.formatted}}</span></div>
                      </mat-radio-button>
                    </label>
                  </mat-radio-group>
                  <mat-checkbox class="clickable-area" *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[item.id][attribute.id]">
                    <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
                  </mat-checkbox>
                  <div class="item-separator" style="margin-top: 15px"></div>
                </div>
              </div>
            </div>
            <div *ngIf="!lastit" class="item-separator"></div>
          </div>
        </div>
      </div>

      <div *ngIf="isDTOpen() && hasItems() && order?.user_cfg?.dt==='table' && mustScan()" class="collect-panel">
        <div class="deliver-planel-content">
          <button mat-button color="primary" style="font-size: 15px;" (click)="openWebcodeDialog()">
            <span >{{getString("Ange webbkoden")}}<br>{{getString("Så vet vi vart det skall levereras")}}</span>
          </button>
        </div>
      </div>

      <div *ngIf="isDTOpen() && hasItems() && order?.user_cfg?.dt==='roomservice' && !order?.user_cfg?.roomnumber" class="collect-panel">
        <div class="deliver-planel-content">
          <button mat-button color="primary" style="font-size: 15px;" (click)="openRoomserviceDialog()">
            <span >Ange {{roomHint}}<br>{{getString("Så vet vi vart det skall levereras")}}</span>
          </button>
        </div>
      </div>

      <div *ngIf="isDTOpen() && hasItems() && order?.user_cfg?.dt==='roomservice' && order?.user_cfg?.roomnumber" class="collect-panel">
        <div class="collect-planel-content">
          <div class="discount-panel">
            <mat-icon style="font-size: 16px; width: 16px;">room_service</mat-icon>
              <div class="under" style="margin-left: 4px;">{{getString("Levereras till")}} {{roomHint}} {{order.user_cfg.roomnumber}}</div>
              <div class="discount-button" style="margin-left: auto;" (click)="openRoomserviceDialog()" mat-ripple *ngIf="!isKiosk">Byt rum</div>
          </div>
        </div>
      </div>

      <div *ngIf="isDTOpen() && hasItems() && order?.user_cfg?.dt==='takeaway'" class="collect-panel">
        <div>
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">schedule</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">{{order?.user_cfg?.delivery_model === 'delivery' ? getString("Leveranstid"):getString("Upphämtningstid")}}</div>
              <div *ngIf="whenReadable" class="under2" style="margin-bottom: 8px;">{{whenReadable}}</div>
            </div>
          </div>
          <div style="padding-left: 20px;" *ngIf="order.user_cfg.collect?.takeaway_time">
            <mat-radio-group [(ngModel)]="pickupTime">
              <label class="clickable-area" style="margin-top: 2px;font-size: 16px;">
                <mat-radio-button [value]="'now'" (change)="timeClassChanged('now')">
                  <div>Hämtas om {{order.user_cfg?.takeaway?.cooking_time}} min</div>
                </mat-radio-button>
              </label>
              <label class="clickable-area" style="margin-top: 8px;font-size: 16px;">
                <mat-radio-button [value]="'later'" (change)="timeClassChanged('later')">
                  <div *ngIf="!order.pickup_time">Välj tid</div>
                  <div *ngIf="order.pickup_time">Hämtas {{order.pickup_time}} <a style="font-size:14px;color:#f39a83;" href (click)="timeClassChanged('later')">(välj annan tid)</a></div>
                </mat-radio-button>
              </label>
            </mat-radio-group>
          </div>
          <div style="margin-bottom: 32px;" *ngIf="order.user_cfg.collect?.slot_time">
            <div class="large-button" mat-ripple (click)="selectSlotTime()">
              <div class="large-button-text">
                <div *ngIf="namespaceName" class="text-under">{{namespaceName}}</div>
                <div *ngIf="pickupDateText">{{order?.user_cfg?.delivery_model === 'delivery' ? getString("Levereras"):getString("Hämtas")}} {{pickupDateText}}</div>
                <span *ngIf="!pickupDateText">{{order?.user_cfg?.delivery_model === 'delivery' ? getString("Välj leveransstid"):getString("Välj upphämtningstid")}}</span>
              </div>
            </div>
          </div>
          <div *ngIf="!order.user_cfg.collect?.takeaway_time && !order.user_cfg.collect?.slot_time">
            <div>Hämtas om {{order.user_cfg?.takeaway?.cooking_time}} min</div>
          </div>
        </div>

        <div *ngIf="order.user_cfg.collect?.pickup_locations" style="margin-top: 16px;">
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">food_bank</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">{{order?.user_cfg?.delivery_model === 'delivery' ? getString("Leveransplats"):getString("Upphämtningsplats")}}</div>
            </div>
          </div>
          <div style="padding-left: 20px;">
            <mat-radio-group [(ngModel)]="pickupLocation">
              <label class="clickable-area" style="margin-top: 2px;font-size: 16px;" *ngFor="let pl of order.user_cfg.collect.pickup_locations">
                <mat-radio-button [value]="pl" (change)="changePickupLocation(pl)">
                  <div>{{order?.user_cfg?.delivery_model === 'delivery' ? getString("Levereras"):getString("Hämtas")}} {{pl}}</div>
                </mat-radio-button>
              </label>
            </mat-radio-group>
          </div>
        </div>
      </div>

        <div *ngIf="isDTOpen() && hasItems() && !mustScan() && (order?.user_cfg?.dt==='table' || (config.show_discount && !isKiosk))" class="collect-panel" style="padding: 6px 16px;">
        <div class="collect-planel-content">
          <div *ngIf="order?.user_cfg?.dt==='table'" class="discount-panel">
            <mat-icon style="font-size: 16px; width: 16px;height: 16px;">room_service</mat-icon>
            <div class="under" style="margin-left: 4px;">{{getString("Serveras till")}} {{order.user_cfg.table}}</div>
            <div class="discount-button" style="margin-left: auto;" (click)="openWebcodeDialog()" mat-ripple>{{getString("Byt bord")}}</div>
          </div>
          <div *ngIf="order?.user_cfg?.dt==='table' && config.show_discount && !isKiosk" class="item-separator"></div>
          <div *ngIf="config.show_discount && !isKiosk" class="discount-panel">
            <mat-icon style="font-size: 16px; width: 16px;height: 16px;">local_offer</mat-icon>
            <div class="under" style="margin-left: 4px;" *ngIf="!order.discount">{{getString("Rabattkod")}}</div>
            <div class="under" style="margin-left: 4px;" *ngIf="order.discount">{{getString("Rabatterat med")}} {{order.discount_formatted}}</div>
            <div class="discount-button" style="margin-left: auto;" (click)="openDiscount()" mat-ripple *ngIf="!order.discount">{{getString("Lägg till")}}</div>
            <div class="discount-button" style="margin-left: auto;" (click)="openDiscount()" mat-ripple *ngIf="order.discount">{{getString("Byt kod")}}</div>
          </div>
        </div>
      </div>

      <div *ngIf="showAnonOrderPanel && !anonPanelOpen" class="under2" style="display: flex; flex-direction: row; align-items: center;margin-left: 12px;">
        <mat-icon style="font-size: 16px;width: 17px;height: 18px;color: #7d7d7d;">play_arrow</mat-icon>
        <a href (click)="openAnonOrderPanel()" class="black-link">{{getString("Maila kvittot efter köp / logga in")}}</a>
      </div>

      <div class="collect-panel"*ngIf="showAnonOrderPanel && anonPanelOpen">
        <div>
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">email</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under" *ngIf="!anonWantsToLogin">{{getString("Ange e-post så skickar vi kvittot dit")}}</div>
              <div class="under" *ngIf="anonWantsToLogin">{{getString("Logga in för spara din historik")}}</div>
            </div>
          </div>
          <div style="margin-top: 8px;" *ngIf="!anonWantsToLogin">
            <mat-form-field class="info-full-width hack" appearance="outline" style="font-size: 15px;">
              <mat-label>{{getString("E-post")}}</mat-label>
              <input matInput [formControl]="emailFormControl" placeholder="Ex. ann@exempel.se" type="email" (change)="anonEmailChanged()">
              <button mat-icon-button matSuffix *ngIf="emailFormControl.value" (click)="clearEmail()"><mat-icon>close</mat-icon></button>
            </mat-form-field>
          </div>
          <div *ngIf="anonWantsToLogin">
            <app-sign-in [config]="config"></app-sign-in>
          </div>
          <div class="under-panel2">
            <div style="margin-left: 6px;">
              <div class="under_b" *ngIf="!anonWantsToLogin">{{getString("Eller")}} <a href (click)="toggleShowAnonLogin()">{{getString("Logga in")}}</a> {{getString("om du vill spara din historik")}}</div>
              <div class="under_b" *ngIf="anonWantsToLogin">{{getString("Eller")}} <a href (click)="toggleShowAnonLogin()">{{getString("Fortsätt utan att logga in")}}</a></div>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="isDTOpen() && customCollects && hasItems()" class="collect-panel">
        <div>
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">contact_page</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">Kontaktinformation</div>
            </div>
          </div>
          <div style="margin-top: 8px;" *ngFor="let collect of customCollects">
            <mat-form-field class="info-full-width hack" appearance="outline" style="font-size: 15px;" floatLabel="always">
              <mat-label>{{collect.title}}</mat-label>
              <input matInput [formControl]="collect.formControl" [placeholder]="collect.placeholder" [type]="collect.inputType" (change)="customChanged(collect)" maxlength="50">
            </mat-form-field>
          </div>
        </div>
      </div>

      <div *ngIf="!isDTOpen()" class="collect-panel">
        <div>
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">block</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">Info</div>
            </div>
          </div>
          <div>
            {{getString("Restaurangen är stängd för beställning")}}
          </div>
        </div>
      </div>
      <div *ngIf="isDTOpen() && hasItems() && canOrder() && !mustScan()" class="payment-panel">
        <div *ngIf="order.user_cfg.payment.open_order" class="payment-button-inv" mat-ripple (click)="send()">
          {{getString("Skicka beställning")}}
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && config.payment.card && isFree()" class="payment-button-inv" mat-ripple (click)="pay('free')">
          {{getString("Skicka beställning")}}
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && config.payment.viva && !isFree() && !isKiosk" class="payment-button" mat-ripple (click)="pay('viva_smart')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/apaygpaytr.png"> VIVA
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && config.payment.card && !isFree() && !isKiosk && config.payment.google_pay_mode !== 'disabled' && !isMessenger" class="payment-button" mat-ripple (click)="pay('card_gp')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/apaygpaytr.png">
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && config.payment.card && !isFree() && !isKiosk" class="payment-button" mat-ripple (click)="pay('card')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/mc_visa.png">
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && config.payment.swish && !isFree()" class="payment-button" mat-ripple (click)="pay('swish')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/swish_open_24.png">
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && order.user_cfg.payment.prepaid_balance && !isFree() && !order.user_cfg.smart_table">
          <div *ngIf="order.user_cfg.payment.prepaid_balance<order.total_pre" class="payment-button">
            <div class="payment-button-text">{{config.payment.prepaid_name || "Rabattkort"}}</div>
          </div>
          <div *ngIf="order.user_cfg.payment.prepaid_balance>=order.total_pre" class="payment-button-card" mat-ripple (click)="pay('prepaid')">
            <div class="payment-button-text">{{config.payment.prepaid_name || "Rabattkort"}}</div>
          </div>
          <div class="under-panel">
            <mat-icon style="font-size: 20px;width: 20px;">card_membership</mat-icon>
            <div style="margin-left: 6px;">
              <div class="under">{{getString("Pris med kort")}} {{order.total_pre|number:'1.2-2'}} kr</div>
              <div *ngIf="order.user_cfg.payment.prepaid_balance>=order.total_pre" class="under2">{{getString("Kort laddat med")}} {{order.user_cfg.payment.prepaid_balance|number:'1.2-2'}} kr</div>
              <div *ngIf="order.user_cfg.payment.prepaid_balance<order.total_pre" class="under2">{{getString("Balans")}} {{order.user_cfg.payment.prepaid_balance|number:'1.2-2'}} kr <a href (click)="refill()">{{getString("Fyll på kort")}}</a></div>
            </div>
          </div>
        </div>
        <div *ngIf="!order.user_cfg.payment.open_order && (order.user_cfg.payment.invoice_payment == 'fortnox_invoice' || order.user_cfg.payment.invoice_payment == 'account_entry') && !isFree()">
          <div *ngIf="true" class="payment-button-card2" mat-ripple (click)="pay('account')">
            <div class="payment-button-text" *ngIf="order.user_cfg.payment.invoice_payment == 'fortnox_invoice'">Betala med faktura</div>
            <div class="payment-button-text" *ngIf="order.user_cfg.payment.invoice_payment == 'account_entry'">Betala med samlingsfaktura</div>
          </div>
        </div>
        <div style="margin-top:24px;font-size:12px;display: flex; justify-content: center">
          <a style="color:gray;" href (click)="terms()">köpvillkor</a>
        </div>
      </div>

      <div class="collect-panel" *ngIf="showLogin">
        <div>
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;">person</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">{{config.name}} {{getString("behöver veta att det är du")}}</div>
            </div>
          </div>
          <div>
            <app-sign-in [config]="config"></app-sign-in>
          </div>
        </div>
      </div>

      <div class="overlay" *ngIf="showSpinner">
        <div class="center">
          <mat-progress-spinner mode="indeterminate" class="spinner">
          </mat-progress-spinner>
        </div>
      </div>

    </div>
    <div class="scrollfooter"></div>
    <div id="formPostDiv"></div>
  </section>
</main>
