import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {TranslateService} from "../../services/translate.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {PhoneUtils} from "../../utils/Utils";
import {VenueConfig} from "../../models/FSConfig";

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  phoneNumber: string;
  verificationCode: string;
  sentCode = false;
  loginPhone = false;
  isAndroid = false;
  phoneLoginError = undefined;
  useTeleLogin = false;
  @Input() config: VenueConfig;
  waiting = false;

  constructor(public authService: AuthService, private translate: TranslateService, private snackbar: MatSnackBar) { }

  ngOnInit(): void {
    this.authService.initializeRecaptcha('recaptcha-container');
    this.isAndroid = navigator.userAgent.toLowerCase().indexOf('android') > -1;
    this.useTeleLogin = this.config?.features?.use_tele_login ?? false;
  }

  getString(s: string) {
    return this.translate.single(s);
  }

  sendCode() {
    this.phoneLoginError = undefined;
    let E164;
    try {
      E164 = PhoneUtils.convertNumberIntoE164Format(this.phoneNumber);
    } catch (e) {
      this.phoneLoginError = "Angivet telefonnummer är inte korrekt";
      return;
    }

    this.waiting = true;
    console.log(`sendCode to ${this.phoneNumber} (${E164})`);
    this.authService.sendVerificationCode(E164).then(res => {
      this.waiting = false;
      this.sentCode = true;
    }).catch((error) => {
      console.error('Error during signInWithPhoneNumber', error);
      const errMsg = error?.message ?? "Ett okänt fel inträffade.";
      this.phoneLoginError = `Angivet telefonnummer är inte korrekt eller annat fel inträffade. ${errMsg}`;      
      this.waiting = false;
      this.sentCode = false;
    });
  }

  onCodeChange() {
    if (this.verificationCode.length === 6) {
      this.verifyCode();
    }
  }

  verifyCode() {
    this.waiting = true;
    this.phoneLoginError = undefined;
    this.authService.verifyCode(this.verificationCode).then(res => {
    }).catch((error) => {
      this.waiting = false;
      console.error('Error while verifying code', error);
      this.phoneLoginError = "Angiven kod är inte korrekt";
    });
  }

  selectPhoneLogin() {
    this.loginPhone = true;
  }
}
