import { Injectable } from '@angular/core';
import {MenuCategory, MenuItem, MenuItemAttribute, MenuSection} from "../models/FSMenu";
import {FireService} from "./fire.service";
import {AppStateService} from "./app-state.service";
import {combineLatest, interval, Observable, of, ReplaySubject, Subscription, timer} from "rxjs";
import {HackUtils} from "../utils/Utils";
import {debounce, filter, first, flatMap, map, tap} from "rxjs/operators";
import {GenjiService} from "./genji.service";
import {Translation} from "../models/Translation";
import {VenueConfig} from "../models/FSConfig";
import {Order} from "../models/Order";

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  public lang = "sv";
  private venueId: number;
  private sub1a: Subscription;
  private sub1b: Subscription;
  private sub2: Subscription;
  private tobeTranslated = [];
  private inTranslationPipe = new Set();
  private config: VenueConfig;
  public useTranslations: boolean;

  private translateSubject = new ReplaySubject<number>(1);
  private translationMap = {};
  public translationsReceivedSubject = new ReplaySubject<number>(1);

  public translationAlertText = "Vänligen notera att valet av språk i vår app aktiverar automatisk översättning. Vi strävar alltid efter att ge korrekta översättningar, men vi kan inte garantera att alla texter kommer att vara 100% korrekta. Om du upptäcker några felaktigheter i översättningarna, var snäll och meddela oss så att vi kan åtgärda dem så snart som möjligt. Vi hoppas att du kommer att njuta av att använda vår app i ditt valda språk!";
  public STRING_ACTIVATE1 = "Aktivera biljetten när du skall hämta ut din beställning och visa den till personalen. Tänk på att biljetten har en tidsgräns på 1 min så aktivera den inte för tidigt.";
  public STRING_ACTIVATE2 = "Du aktiverar biljetten genom att dra pilen åt höger.";
  public ALL_IS_PAID1 = "Här är det möjligt att betala och dela upp notan för beställningar vid samma bord. När det finns en obetald nota så visas den här.";
  public ALL_IS_PAID2 = "Just nu är allt betalt 👍";
  public SHOW_TICKET = "Visa upp biljetten i samband med att du hämtar ut din beställning. Bon appetit!";
  public WEEK_EMPTY = "Veckans meny innehåller inga artiklar för denna dag";

  validSingleStrings = ["SLUTSÅLD","Varukorg","Mat","Dryck","Betala","Notan","Stäng","Lägg till","Ange webbkoden",
    "Så vet vi vart det skall levereras","Levereras till","Ange e-post så skickar vi kvittot dit","Logga in för spara din historik",
    "E-post", "Logga in", "om du vill spara din historik", "Fortsätt utan att logga in", "Restaurangen är stängd för beställning",
    "Skicka beställning", "behöver veta att det är du", "Totalt", "inkl. moms", "Språk",
    "Betala nota", "Händelser", "Mitt konto", "Mina kvitton", "Veckans meny", "Byt meny",
    "Anpassa rätten (allergier och innehåll)", "utan", "och", "INNEHÅLLER","Ange webbkoden", "WEBBKOD", "Du kan även skanna QR-kod med din kamera",
    "Felaktig webbkod", "Serveras till", "Byt bord", "Jag har en", "Rabattkod", "Rabatterat med", "Ange annan", "Passar bra till",
    "Meny", "Välkommen till", "Välj meny nedan", "Fortsätt med", "Rabatt", "Ditt mobilnummer", "Ange kontaktuppgifter",
    "Bekräfta din plats", "Är du kvar på", "Ja", "Byt bord", "Kalla på servitör", "Går inte att få utan", "Kan fås utan",
    "Info","Du har inte lagt till något i din beställning ännu. Välj mat och dryck från menyn.", "Fortsätt", "Namn", "E-post", "Konto skapat", "Logga ut",
    "KOPPLA MESSENGER-KONTO", "Kontotyp", "Du har artiklar i din beställning", "Om du byter leveranssätt så kommer din beställning att tömmas.", "Avbryt",
    "Gåva", "Ange rabattkod", "Byt kod", "Ingen dricks", "❤️ 5% dricks", "😍 10% dricks", "😍 5kr dricks", "❤️ 10kr dricks", "🔥Valfritt belopp🔥",
    "Tyvärr kunde vi inte genomföra betalningen.", "Välj totalbelopp", "Betala", "Sparat kort", "Annat kort",
    "Beställningen är betald", "Tack för ditt köp!", "Din beställning", "Tack för din beställning.", "Vi kommer strax ut med den.",
    this.STRING_ACTIVATE1, this.STRING_ACTIVATE2, this.ALL_IS_PAID1, this.ALL_IS_PAID2, this.SHOW_TICKET, this.WEEK_EMPTY,
    "Notan är betald. Beställningsnummer", "Kvitto", "Returkvitto", "Maila kvittokopia", "Miljövänligt kvitto", "Elektroniska kvitton är ett bra miljöval.",
    "Välj alla", "kvar på notan", "Reserverad av", "Betalad av", "dela", "Dela upp", "Hur många personer skall dela?", "Kassakvitto",
    "Maila kvittot efter köp / logga in", "Eller", "Notan är betald", "Tips: logga in för att spara kvittot", "Dela notan",
    "Wow! 😍 😍", "och övrig personal gör allt för att du skall ha en trevlig upplevelse, så stort tack för dricksen!", "Tack för dricksen!", "kommer strax ut med din beställning.",
    "Hoppas att allt var till din belåtenhet.", "Tack!", "Skicka kvittokopia", "Spara email", "Mån,Tis,Ons,Tor,Fre",
    "Pris med kort", "Kort laddat med", "Fyll på kort", "Fyll på kort med", "Balans", "Kan användas vid betalning i kassan", "Logga in för att fylla på kort", "Ingår i beställning",
    "Gemensam nota", "Beställningar som personalen gjort", "Beställningar som du delar med andra", "Beställningar som du gjort",
    "Verifiera med telefon", "Skickar kod...", "Verifierar kod...", "Levereras", "Hämtas", "Leveransplats", "Upphämtningsplats"
  ];

  constructor(private fire: FireService, private state: AppStateService, private genji: GenjiService) {
    state.observeAppState().subscribe(appState => {
      this.useTranslations = appState?.cfg?.use_translations === true;
      if (this.useTranslations) {
        const langChanged = appState != null && appState.lang != null && appState.lang !== this.lang;
        const venueChanged = appState != null && appState.venueId != null && appState.venueId !== this.venueId;
        if (langChanged || venueChanged) {
          this.config = appState.cfg;
          this.prepapreTranslation(appState.venueId, appState.lang);
        }
      }
    });
  }

  private prepapreTranslation(venueId: number, lang: string) {
    this.venueId = venueId;
    this.lang = lang;
    if (lang === "sv") {
      console.log(`No translation needed ${lang}`);
      return;
    }

    console.log(`Prepare translation to ${lang}, ${venueId}`);
    this.sub1a?.unsubscribe();
    this.sub1b?.unsubscribe();
    this.translationMap = {};
    this.inTranslationPipe.clear();

    // Observe venue specific translations
    this.sub1a = this.fire.observeTranslations(venueId, lang).subscribe(translations => {
      const n = this.addToTranslationMap(translations);
      if (n>0) {
        console.log(`Received ${n} translations for ${lang} (${venueId})`);
        this.translationsReceivedSubject.next(n);
      }
    });

    // Observe global translations
    this.sub1b = this.fire.observeTranslations(1, lang).subscribe(translations => {
      this.addToTranslationMap(translations);
    });

    this.sub2?.unsubscribe();
    this.sub2 = this.translateSubject.pipe(debounce(() => timer(500))).subscribe(n => {
      this.executeTranslation();
    });
  }

  single(s: string, postCase = "normal"): string {
    if (this.lang === "sv") { return s; }
    let r: string;
    if (s != null && this.validSingleStrings.includes(s)) {
      const ts = this.translateString(s, false, true);
      r = ts.text;
    } else {
      //Just try to resolve it, if it's not a valid single
      const strId = HackUtils.simpleBitHashHex(s);
      const translated = this.resolveTranslation(strId);
      if (translated != null) {
        r = translated;
      }
    }
    if (r == null) {
      r = s;
    }
    if (postCase === "upper") {
      return r.toUpperCase();
    } else if (postCase === "lower") {
      return r.toLowerCase();
    } else if (postCase === "cap" && r.length > 0) {
      return r.charAt(0).toUpperCase() + r.slice(1);
    }
    return r;
  }

  attributes(attrList: MenuItemAttribute[], dontTranslate: boolean) {
    if (this.lang === "sv") { return {text: null, ok: true}; }
    if (attrList != null) {
      for (const atr of attrList) {
        for (const option of atr.options) {
          const tn = this.translateString(option.value, dontTranslate);
          option.value = tn.text;
          if (option.contains != null) {
            for (const c of option.contains) {
              const tc = this.translateString(c.name, dontTranslate);
              c.name = tc.text;
            }
          }
        }
      }
    }
    return {text: null, ok: false};
  }

  items(items: MenuItem[]): MenuItem[] {
    if (this.lang === "sv") { return items; }
    if (items != null) {
      for (const item of items) {
        this.item(item);
      }
    }
    return items;
  }

  item(item: MenuItem) {
    if (this.lang === "sv") { return item; }
    if (item.lang !== this.lang) {
      const dontTranslate = item.lang === "-";
      item.lang = "-";
      const tn = this.translateString(item.name, dontTranslate);
      item.name = tn.text;
      const td = this.translateString(item.desc, dontTranslate);
      item.desc = td.text;
      const ta = this.attributes(item.attributes, dontTranslate);
      if (item.contains != null) {
        for (const c of item.contains) {
          this.translateString(c.name, dontTranslate);
        }
      }
      if (item.adjusts != null) {
        for (const a of item.adjusts) {
          this.translateString(a.name, dontTranslate);
        }
      }
      if (item.pushes != null) {
        for (const p of item.pushes) {
          this.translateString(p.title, dontTranslate);
          for (const pg of p.groups) {
            this.translateString(pg.title, dontTranslate);
          }
        }
      }
      item.lang = tn.ok && td.ok && ta.ok ? this.lang : "-";
    }
  }

  categories(cats: MenuCategory[]) {
    if (this.lang === "sv") { return cats; }
    if (cats != null) {
      for (const cat of cats) {
        //console.log("Translate cat: ", cat);
        if (cat.lang !== this.lang) {
          const dontTranslate = cat.lang === "-";
          cat.lang = "-";
          const tn = this.translateString(cat.name, dontTranslate);
          cat.name = tn.text;
          const td = this.translateString(cat.desc, dontTranslate);
          cat.desc = td.text;
          cat.lang = tn.ok && td.ok ? this.lang : "-";
        }
      }
    }
    return cats;
  }

  sections(sections: MenuSection[]): MenuSection[] {
    if (this.lang === "sv") { return sections; }
    if (sections != null) {
      for (const section of sections) {
        if (section.lang !== this.lang) {
          const dontTranslate = section.lang === "-";
          section.lang = "-";
          const tn = this.translateString(section.name, dontTranslate);
          section.name = tn.text;
          const td = this.translateString(section.desc, dontTranslate);
          section.desc = td.text;
          section.lang = tn.ok && td.ok ? this.lang : "-";
        }
      }
    }
    return sections;
  }

  order(order: Order) {
    if (this.lang === "sv") { return; }
    if (order != null) {
      for (const item of order.items) {
        if (item.customized) {
          const parts = item.customized.split(",");
          console.log("Translate parts: ", parts);
          const translated = [];
          for (const part of parts) {
            const p = part.trim();
            const tr = this.translateString(p, true);
            translated.push(tr.text);
          }
          console.log("Translated parts: ", translated);
          item.customized = translated.join(", ");
        }
      }
    }
  }

  private translateString(phrase: string, dontTranslate: boolean, globalTranslation = false): {text: string, ok: boolean} {
    if (this.lang === "sv") { return {text: phrase, ok: true}; }
    if (phrase == null) {
      return {text: null, ok: true};
    }
    const strId = HackUtils.simpleBitHashHex(phrase);
    const translated = this.resolveTranslation(strId);
    if (translated != null) {
      return {text: translated, ok: true};
    }
    if (!this.inTranslationPipe.has(strId) && !dontTranslate) {
      this.translateSubject.next(1);
      const venueId = globalTranslation ? 1 : this.venueId;
      this.tobeTranslated.push({strId, phrase, venueId});
      this.inTranslationPipe.add(strId);
    }
    return {text: phrase, ok: false};
  }

  private resolveTranslation(strId: string) {
    const t = this.translationMap[strId];
    if (t != null) {
      return t.translation;
    }
    return null;
  }

  private executeTranslation() {
    const filtered = this.filterAlreadyTranslated(this.tobeTranslated);
    this.tobeTranslated = [];
    if (filtered.length > 0) {
      console.log("Execute translations: ", filtered);
      const phrasePairs = filtered.map(p => ({phrase: p.phrase, str_id: p.strId, venue_id: p.venueId}));
      this.genji.translate(phrasePairs, this.lang).subscribe(res => {
        console.log("res: ", res);
      }, error => {
        console.error("Failed to execute translations...", error);
      });
    }
  }

  private filterAlreadyTranslated(translatePairs: any[]): any[] {
    const filtered = [];
    for (const pair of translatePairs) {
      const translated = this.resolveTranslation(pair.strId);
      if (translated == null) {
        filtered.push(pair);
      }
    }
    return filtered;
  }

  private addToTranslationMap(translations: Translation[]): number {
    console.log("Translations received", translations);
    let receivedNewTranslation = 0;
    for (const t of translations) {
      if (this.translationMap[t.str_id] !== t) {
        this.translationMap[t.str_id] = t;
        receivedNewTranslation += 1;
      }
    }
    return receivedNewTranslation;
  }

  public observeAlert(lang: string): Observable<Translation> {
    if (!this.useTranslations) { return; }
    const phrase = this.translationAlertText;
    const strId = HackUtils.simpleBitHashHex(phrase);
    return this.fire.getTranslation(1, lang, strId).pipe(
      flatMap(translation => {
        if (translation != null) {
          return of(translation);
        } else {
          return this.genji.translate([{phrase, str_id: strId, venue_id: 1}], lang).pipe(
            flatMap(res => {
              return this.fire.observeTranslation(1, lang, strId);
            }),
            filter(t => t != null),
            first()
          );
        }
      })
    );
  }

  public prepareLangTranslation(code): Observable<boolean> {
    console.log("Prepare lang translation: ", code, this.venueId);
    this.prepapreTranslation(this.venueId, code);
    this.kickOffVenueConfigTranslations();
    for (const s of this.validSingleStrings) {
      this.translateString(s, false, true);
    }

    return combineLatest([
      this.fire.observeTranslations(1, code),
      interval(1000)]
    ).pipe(
      tap(([translations, n]) => console.log("Translation count: ", translations.length, this.validSingleStrings.length, "tobe: ", this.tobeTranslated.length)),
      map<[Translation[], number], boolean>(([translations, n]) => translations.length > this.validSingleStrings.length-4 && this.tobeTranslated.length < 1)
    );
  }

  private kickOffVenueConfigTranslations() {
    if (this.config.dt.inhouse) {
      this.translateString(this.config.dt.inhouse.title, false, false);
      this.translateString(this.config.dt.inhouse.desc, false, false);
    }
    if (this.config.dt.takeaway) {
      this.translateString(this.config.dt.takeaway.title, false, false);
      this.translateString(this.config.dt.takeaway.desc, false, false);
    }
    if (this.config.dt.roomservice) {
      this.translateString(this.config.dt.roomservice.title, false, false);
      this.translateString(this.config.dt.roomservice.desc, false, false);
    }
    if (this.config.dt.pickup) {
      this.translateString(this.config.dt.pickup.title, false, false);
      this.translateString(this.config.dt.pickup.desc, false, false);
    }
    if (this.config.dt.foodcourt) {
      this.translateString(this.config.dt.foodcourt.title, false, false);
      this.translateString(this.config.dt.foodcourt.desc, false, false);
    }
  }

  isActive() {
    return this.lang !== "sv";
  }

  getBrowserLang() {
    if (!this.useTranslations) { return "sv-SE"; }
    return navigator.language;
  }

}
