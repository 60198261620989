<main>
  <section>
    <div *ngIf="!isLoading" class="container">
      <div class="back" (click)="backClicked()">
        <mat-icon style="font-size: 27px">navigate_before</mat-icon> <div style="margin: 3px 0 0 5px;">{{getString("Betala nota", "upper")}}</div>
      </div>

      <div class="sub-cont">
        <div *ngIf="nothingToPay" class="panel panel-primary">
          <div class="panel-body">
            {{getString(translate.ALL_IS_PAID1)}}
            <br><br>
            {{getString(translate.ALL_IS_PAID2)}}
          </div>
        </div>

        <!-- LIVESPLIT -->
        <div *ngIf="splitType==='livesplit'">
          <div *ngFor="let group of groups" class="receipt-row">
            <div class="group-header"><div>{{group.name}}</div><a class="select-all" *ngIf="!group.selected" (click)="selectAll(group)">{{getString("Välj alla")}}</a></div>
            <div *ngIf="group.hint" class="hint">{{group.hint}}</div>
            <div *ngFor="let item of group.items">
              <div *ngIf="shouldItemBeVisible(item)" class="receipt-row">
                <mat-checkbox class="clickable-area" [disabled]="!item.canChange" [(ngModel)]="item.selected" (change)="itemSelected(item)">
                  <div><b *ngIf="!item.parts && item.count>1">{{item.count}}x&nbsp;</b><b *ngIf="item.parts">{{item.count}}/{{item.parts}}&nbsp;</b>{{getString(item.name)}} - <i *ngIf="item.comment" style="color: #888;font-size: 15px;"> {{item.comment}} - </i><i style="color: #888;font-size: 15px;"> {{getPrice(item)}} kr</i></div>
                </mat-checkbox>
                <div [ngClass]="{'mine':item.canChange,'others':!item.canChange}" *ngIf="item.state === 'reserved'">{{getString("Reserverad av")}} <span *ngIf="item.user_id != 0">{{item.user}}</span><a *ngIf="item.rid===lastRid && item.parts === undefined" style="margin-left:4px;color: #2196F3;text-decoration: underline;" (click)="splitItems(item)">({{getString("dela")}})</a></div>
                <div [ngClass]="{'mine':item.canChange,'others':!item.canChange}" *ngIf="item.state === 'paid'"><span *ngIf="item.user_id">{{getString("Betalad av")}} {{item.user}}</span></div>
              </div>
            </div>
          </div>
        </div>

        <!-- CHAT RECEIPT -->
        <div *ngIf="splitType==='chat' || splitType==='pl'">
          <div *ngFor="let item of data.items">
            <div *ngIf="shouldItemBeVisible(item)" class="receipt-row">{{item.count}}<span *ngIf="item.parts">/{{item.parts}}</span><span style="font-style: normal;margin-left: 2px;">x</span> {{getString(item.name)}} - <i *ngIf="item.comment" style="color: #888;font-size: 15px;"> {{item.comment}} - </i><i style="color: #888;font-size: 15px;"> {{getPrice(item)}} kr</i></div>
          </div>
        </div>

        <!-- SUMMARY -->
        <div *ngIf="!nothingToPay" class="total-panel">
          <div *ngIf="left > 0" style="font-size: 14px;color: #888;margin-bottom: 8px;">{{getString("kvar på notan")}}: {{leftFormatted}}</div>
          <div>{{getString("Totalt")}}: <b>{{priceFormatted}}</b></div>
        </div>

        <!-- DONATIONS -->
        <div *ngIf="!nothingToPay && inlineDonations" class="collect-panel" style="margin-top: 14px;margin-bottom: 26px;">
          <div class="collect-panel-title">
            <mat-icon style="font-size: 16px; width: 16px;color:red;">favorite</mat-icon>
            <div style="margin-left: 4px;">
              <div class="under">Gåva</div>
            </div>
          </div>
          <div>
            <div *ngFor="let item of inlineDonations; let lastit = last;" class="push-item">
              <mat-checkbox class="clickable-area" [(ngModel)]="selectedItems[item.id]" (change)="selectedChange(item)">
                <div class="push-name">{{item.name}}</div>
              </mat-checkbox>
              <div style="padding-left: 33px">
                <div *ngIf="item.desc" class="desc">{{item.desc}}</div>
                <div *ngIf="selectedItems[item.id]">
                  <div *ngFor="let attribute of item.attributes | filterHiddenAtr; let atri = index; let lastatr = last;">
                    <mat-radio-group *ngIf="attribute.options.length>1" [(ngModel)]="attrValues[item.id][attribute.id]">
                      <label class="clickable-area" style="margin-top: 8px;font-size: 16px;" *ngFor="let option of attribute.options; let opti = index; let lastopt = last;">
                        <mat-radio-button [value]="option.key">
                          <div><span class="atr-name">{{option.value | attributeCutter}}</span><span> {{option.formatted}}</span></div>
                        </mat-radio-button>
                      </label>
                    </mat-radio-group>
                    <mat-checkbox class="clickable-area" *ngIf="attribute.options.length===1;" [(ngModel)]="attrValues[item.id][attribute.id]">
                      <div><span class="atr-name">{{attribute.options[0].value}}</span><span style="font-size: 16px;"> {{attribute.options[0].formatted}}</span></div>
                    </mat-checkbox>
                    <div *ngIf="!lastatr" class="item-separator" style="margin-top: 15px"></div>
                  </div>
                </div>
              </div>
              <div *ngIf="!lastit" class="item-separator"></div>
            </div>
          </div>
        </div>

      </div>

      <div *ngIf="!nothingToPay && config" class="payment-panel">
        <div *ngIf="(splitType==='chat' || splitType==='pl') && allowSplit" class="payment-button-misc" style="margin-bottom: 16px;" mat-ripple (click)="activateSplit()">
          <div class="split-button-text">{{getString("Dela notan", "upper")}}</div>
        </div>
        <div *ngIf="config.payment.viva" class="payment-button" mat-ripple (click)="pay('viva_smart')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/apaygpaytr.png">VIVA
        </div>
        <div *ngIf="config.payment.card && config.payment.google_pay_mode !== 'disabled' && !isMessenger" class="payment-button" mat-ripple (click)="pay('card_gp')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/apaygpaytr.png">
        </div>
        <div *ngIf="config.payment.card" class="payment-button" mat-ripple (click)="pay('card')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/mc_visa.png">
        </div>
        <div *ngIf="config.payment.swish" class="payment-button" mat-ripple (click)="pay('swish')">
          <img style="margin-top: 5px; margin-left: 8px;" src="https://storage.googleapis.com/heynow/media/swish_open_24.png">
        </div>


        <div *ngIf="isAnon" style="display:flex;margin-top: 32px;justify-content: center;">
          <button mat-button color="primary" (click)="login()" style="font-family: 'Montserrat', sans-serif;color:#aaa;">{{getString("Tips: logga in för att spara kvittot")}}</button>
        </div>

        <div style="margin-top:24px;font-size:12px;display: flex; justify-content: center">
          <a style="color:#aaa;" href (click)="terms()">köpvillkor</a>
        </div>
      </div>

      <div *ngIf="splitType==='livesplit'" class="footer-livesplit">heynow livesplit™</div>
    </div>
    <div class="scrollfooter"></div>
    <div id="formPostDivLS"></div>
  </section>
</main>
<mat-spinner class="spinner" *ngIf="isLoading || isSending"></mat-spinner>
